import { createApp } from 'vue'
import './style.css'
import App from './App.vue'
import PopApp from './Popup.vue'
import {detect, open} from "@/global/pop.js";

// wait for document to be ready
(function (window) {
    (function docReady(fn) {
        // see if DOM is already available
        if (document.readyState === "complete" || document.readyState === "interactive") {
            // call on next available tick
            setTimeout(fn, 1);
        } else {
            document.addEventListener("DOMContentLoaded", fn);
        }
    }(function () {
        const detectElements = detect(window);
        if(document.getElementById('virtual-admin-app')){
            const app = createApp(App);

            Object.values(
                import.meta.glob('./modules/*.js', { eager: true })
            ).forEach(module => module.install?.({ app }))

            app.mount('#virtual-admin-app')
        }

        if(document.getElementById('virtual-admin-popup')){
            const app = createApp(PopApp);
            Object.values(
                import.meta.glob('./modules/*.js', { eager: true })
            ).forEach(module => module.install?.({ app }))
            app.mount('#virtual-admin-popup')
        }

        const e2watch = Object.values(detectElements).filter((element) => {
            return Array.isArray(element) && element.length > 0 ? true : (
                !Array.isArray(element) && element !== null
            );
        }).flat();

        function addIcon(currentElement){
            if([...currentElement.getElementsByClassName('admin-edit-icon')].length > 0){
                return false;
            }

            setTimeout(() => {
                const childElement = document.createElement('div');
                childElement.classList.add('admin-edit-icon');
                childElement.innerHTML = "<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 20 20\" fill=\"currentColor\" class=\"size-5\">\n" +
                    "<path fill-rule=\"evenodd\" d=\"M8.34 1.804A1 1 0 0 1 9.32 1h1.36a1 1 0 0 1 .98.804l.295 1.473c.497.144.971.342 1.416.587l1.25-.834a1 1 0 0 1 1.262.125l.962.962a1 1 0 0 1 .125 1.262l-.834 1.25c.245.445.443.919.587 1.416l1.473.294a1 1 0 0 1 .804.98v1.361a1 1 0 0 1-.804.98l-1.473.295a6.95 6.95 0 0 1-.587 1.416l.834 1.25a1 1 0 0 1-.125 1.262l-.962.962a1 1 0 0 1-1.262.125l-1.25-.834a6.953 6.953 0 0 1-1.416.587l-.294 1.473a1 1 0 0 1-.98.804H9.32a1 1 0 0 1-.98-.804l-.295-1.473a6.957 6.957 0 0 1-1.416-.587l-1.25.834a1 1 0 0 1-1.262-.125l-.962-.962a1 1 0 0 1-.125-1.262l.834-1.25a6.957 6.957 0 0 1-.587-1.416l-1.473-.294A1 1 0 0 1 1 10.68V9.32a1 1 0 0 1 .804-.98l1.473-.295c.144-.497.342-.971.587-1.416l-.834-1.25a1 1 0 0 1 .125-1.262l.962-.962A1 1 0 0 1 5.38 3.03l1.25.834a6.957 6.957 0 0 1 1.416-.587l.294-1.473ZM13 10a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z\" clip-rule=\"evenodd\" />\n" +
                    "</svg>\n";
                childElement.addEventListener('click', () => {
                    if(currentElement.id === 'app'){
                        open('app');
                    }
                    else if(currentElement.classList.contains('slider')){
                        const currentId = currentElement.dataset.id;
                        open('slider&id=' + currentId);
                    }
                    else if(currentElement.id === 'quick_search'){
                        open('quick_search');
                    }
                });
                currentElement.appendChild(childElement);
                currentElement.classList.add('admin-edit-allow')
            }, 500);
        }

        const observer = new MutationObserver((mutationsList) => {
            mutationsList.forEach(mutation => {
                if (mutation.type === 'attributes') {
                    addIcon(mutation.target);
                }
            });
        });

        /**
         * @var {document} element
         */
        e2watch.forEach(element => {
            if(element.hasAttribute('data-v-app')){
                addIcon(element);
                return;
            }
            observer.observe(element, { attributes: true, subtree: false, attributeFilter: ['data-v-app'] });
        });
    }))
})(window);