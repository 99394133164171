<template>
  <div class="block p-4 hover:text-gray-600 dark:hover:text-gray-300 flex gap-4 items-center">
    <div class="flex-1">
      <slot name="default" />
    </div>
    <div class="w-32 flex flex-row-reverse gap-2" v-if="setupClick || cleanupClick || rollbackClick">
      <svg
          v-if="cleanupClick"
          @click.stop="autoCleanup($t)"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="size-6"
      >
        <path stroke-linecap="round" stroke-linejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
      </svg>
      <svg
        v-if="setupClick"
        @click.stop="autoSetupClick($t)"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="size-6"
      >
        <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 6.75h12M8.25 12h12m-12 5.25h12M3.75 6.75h.007v.008H3.75V6.75Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0ZM3.75 12h.007v.008H3.75V12Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm-.375 5.25h.007v.008H3.75v-.008Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z" />
      </svg>
      <svg
          v-if="rollbackClick"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="size-6"
          @click.stop="autoRollback($t)"
      >
        <path stroke-linecap="round" stroke-linejoin="round" d="M9 15 3 9m0 0 6-6M3 9h12a6 6 0 0 1 0 12h-3" />
      </svg>
    </div>
  </div>
</template>
<script setup>
import {inject} from "vue";
import HeaderBarAlert from "@/components/share/HeaderBarAlert.vue";

const props = defineProps({
  setupClick : {
    type: Function,
    default: null
  },
  cleanupClick: {
    type: Function,
    default: null
  },
  rollbackClick:{
    type: Function,
    default: null
  }
});

const model = inject('modal');
const modal_pop = inject('modal_pop');

function autoSetupClick($t){
  model(HeaderBarAlert, {message:$t('global.are_you_sure_setup_default'), okAction:() => {
    props.setupClick();
    modal_pop();
  }, cancelAction:() => {
    modal_pop();
  }}, {label:$t('global.are_you_sure_setup_default_label')})
}

function autoCleanup($t){
  model(HeaderBarAlert, {message:$t('global.are_you_sure_cleanup_default'), okAction:() => {
      props.cleanupClick();
      modal_pop();
  }, cancelAction:() => {
      modal_pop();
  }}, {label:$t('global.are_you_sure_cleanup_default_label')})
}

function autoRollback($t){
  model(HeaderBarAlert, {message:$t('global.are_you_sure_rollback'), okAction:() => {
      props.rollbackClick();
      modal_pop();
  }, cancelAction:() => {
      modal_pop();
  }}, {label:$t('global.are_you_sure_rollback_label')})
}
</script>