import {ref, watch} from "vue";
import isEqual from "lodash.isequal";
import {set} from "@vueuse/core";
/**
 * missing function in virtual config is department widget
 *-----------------------------------------------------*/
function outputNonDefinedProperties(params, object, name, map = {}){
    try{
        if(Object.keys(params).length > 0){
            for(let key in params){
                if(!(object.hasOwnProperty(key) === true || object.hasOwnProperty(map?.[key]) === true)){
                    console.info(`Property ${key} is not defined in ${name}`);
                }
            }
        }
    }
    catch (e) {
        console.log('error in outputNonDefinedProperties: ' + name)
    }
}

const Int = (value) => {
    if(value === undefined){
        return undefined;
    }

    return parseInt(String(value));
};

function TextObject(value){
    return value === undefined ? undefined : value;
}

const Mixed = (value) => {
    if(value === undefined){
        return undefined;
    }

    return value;
}

function getType(value){
    switch (value){
        case String:
            return 'string';
        case Int:
            return 'number';
        case Boolean:
            return 'boolean';
        case TextObject:
            return 'text_object';
        default:
            return 'undefined';
    }
}

function stringify(obj){
    return JSON.stringify(obj, function(key, val) {
        if(typeof val === 'object' && val instanceof DefaultSettingsComponent){
            return;
        }
        if(key.startsWith('_')){
            return;
        }

        return val;
    });
}



function oldFunctionFormat(newValue, params, output){
    if(!newValue.startsWith('data.')){
        return newValue;
    }

    const regex = new RegExp(`^data.(.*)$`, 'i');
    const value = newValue?.match(regex);
    newValue = output(value);

    switch (params?.f){
        case 'currency':
            newValue = `this.currency(${newValue})`;
            break;
        case 'date':
            newValue = `this.date(${newValue})`;
            break;
        case 'numberFormat':
            newValue = `this.number(${newValue})`
            break;
        case 'int':
            newValue = `this.int(${newValue})`
            break;
        case 'replace':
            newValue = `${newValue}.replace(1,2)`
            break;
        case 'substr':
            newValue = `${newValue}.substr(1,2)`;
            break;
    }

    newValue = `\${${newValue}\}`

    return newValue;
}

function setIfEmpty(value, defaultValue = undefined){
    return value === undefined || value === null || value === '' ? defaultValue : value;
}

function setIfEmptyObject(value, defaultValue = undefined){
    if(typeof value !== 'object'){
        return defaultValue;
    }

    // check if values is empty
    if((Array.isArray(value) && value.length === 0) || (!Array.isArray(value) && Object.keys(value).length === 0)){
        return defaultValue;
    }

    return value;
}

function setColorValue(value, defaultValue){
    return setIfEmpty(value, defaultValue);
}

class CustomJson{
    data = undefined;
    constructor(params) {
        this.data = params ?? undefined;
    }

    output(){
        // if this.data is array
        if(Array.isArray(this.data)){
            const items = this.data.filter((item) => item !== undefined && item !== null);

            if(items.length > 0){
                return items;
            }

            return undefined;
        }

        return this.data ?? undefined;
    }
}

class CustomJsonString{
    content = '';
    constructor(params = {}) {
        try{
            this.content = JSON.stringify(params||{}, null, 2);
        }
        catch(e) {
            this.content = '{}'
        }
    }

    output(){
        let data = {};

        try{
            data = JSON.parse(this.content);
        }
        catch (e){
            data = undefined;
        }

        if(typeof data === 'object' && Array.isArray(data) && data?.length > 0){
            return data;
        }
        else if(typeof data === 'object' && !Array.isArray(data) && Object.keys(data).length > 0){
            return data;
        }

        return undefined;
    }
}

class BaseArray extends Array{
    constructor(){
        super();
    }
    _update(object, root){
        object.forEach((item) => {
            super.push(item);
        })

        Object.keys(object).forEach(key => {
            this[key] = object[key];
        })

        this.root = root;

        return this;
    }

    static instance(data, root){
        return (new this())._update(data, root)
    }
}
class DefaultSettingsBase{
    _defaultValues = {}
    _outputKeys = {}
    _outputFilter = {}
    _layout = {}
    _params = {}
    _props = {}
    _type = {}
    _anyData = {}
    _orgValues = {}
    root = {};
    init(params, layout, _name, enableType = false, root) {
        if (_name === undefined){
            _name = this.constructor.name;
        }
        if (this.hasOwnProperty('_hidden') && params && Object.keys(params).length === 0){
            this._hidden = true;
        }

        if (this.hasOwnProperty('root') && root !== undefined){
            this.root = root;
        }

        this._params = params;

        for (let key in layout) {
            let [type, _classType, field, defaultValue, customOutputFilter, anyData] = layout[key];
            this._defaultValues[key] = defaultValue;
            this._outputFilter[key] = customOutputFilter ?? function (value) {
                return value;
            };
            this._outputKeys[key]   = field;
            this._layout[key]       = layout[key]
            this._props[field]      = key;

            if(anyData !== undefined){
                this._anyData[key] = anyData ?? {};
            }

            if(enableType) {
                this._type[field] = getType(type);
            }

            this._setValue(key)
        }

        outputNonDefinedProperties(params, this, _name, this._props);
    }

    _setValue(key){
        let [type, classType, field, defaultValue] = this._layout[key];
        const currentValue = this._params[field];

        if(field.startsWith('symbiote_') && typeof classType === "function" && Object.getPrototypeOf(classType.prototype).constructor === Symbiote){
            this[key] = new classType(this._params, this.root);
        }
        else if (typeof classType === "function" && typeof type === "function") {
            let value = currentValue ?? undefined;

            switch (type) {
                case Array:
                    if(value !== undefined){
                        value = Array.isArray(value) ? value : [value];
                    }
                    else{
                        value = [];
                    }

                    break;
                case Object:
                    value = typeof value === "object" ? value : {};
                    break;
                default:
                    value = type(value);
                    break;
            }
            value = (value !== undefined ? value : defaultValue);

            this[key] = new classType(value, this.root);
            this._orgValues[key] = new classType(value, this.root);
        }
        else if(typeof type === "function"){
            /** Fix output for boolean - so false not return default value */
            if(type === Boolean){
                if(currentValue === true || currentValue === false){
                    this[key] = currentValue;
                    this._orgValues[key] = currentValue;
                }
                else{
                    this[key] = defaultValue;
                    this._orgValues[key] = defaultValue;
                }
            }
            else if(type === Int){
                // if int so 0 is valid value.
                // undefined is not valid value or null
                if(currentValue !== undefined && currentValue !== null){
                    this[key] = Int(currentValue);
                    this._orgValues[key] = Int(currentValue);
                }
                else{
                    this[key] = defaultValue;
                    this._orgValues[key] = defaultValue;
                }
            }
            else{
                this[key] = (currentValue ? type(currentValue) : defaultValue);
                this._orgValues[key] =(currentValue ? type(currentValue) : defaultValue);
            }
        }
        else{
            console.log('missing ' + field in this.toString());
        }
    }

    output(){
        let output = Object.entries(this).reduce((acc, [key, value]) => {
            if(key === '_defaultValues' || key === '_outputKeys'){
                return acc;
            }
            else if(!this._outputKeys?.[key]){
                return acc;
            }

            if(value !== undefined && value instanceof Symbiote){
                let output = value.output();

                if(output === undefined){
                    return acc;
                }

                Object.entries(output).forEach(([_key, _value])=>{
                    // check if field is in acc and if yes - override but make error in console about problem - so we have info if we need change it.
                    if(acc.hasOwnProperty(_key)){
                        console.warn("Symbiot " + key + " has own key" + _key + " that override normal defined key in parent class - if that is wrong fix it...");
                    }
                    acc[_key] = _value;
                });


                return acc;
            }

            // first check if value method output exists
            if(value?.output !== undefined && typeof value?.output === "function"){
                let output = this._outputFilter[key](value.output());

                if(output !== this._defaultValues[key]){
                    acc[this?._outputKeys?.[key]] = output;
                }
            }
            else {
                let output = this._outputFilter[key](value);

                if(output !== this._defaultValues[key]){
                    acc[this._outputKeys?.[key]] = output;
                }
            }

            return acc;
        }, {});

        if (Object.keys(output).length === 0) {
            return undefined;
        }

        return output;
    }

    getLayoutType(field){
        if(this?._type?.[field] === undefined){
            this._type[field] = getType(this.getLayoutTypeRaw(field));
        }

        return this._type[field];
    }
    getLayoutTypeRaw(field){
        return this._layout[field][0];
    }
    setRollback(fieldName){
        this._setValue(fieldName)
    }
    setDefault(fieldName){
        if(typeof this[fieldName] === 'object' && Array.isArray(this[fieldName])){
            this[fieldName].splice(0, this[fieldName].length);
            if((this._defaultValues?.[fieldName]?.length ??0) > 0){
                this[fieldName].push([...this._defaultValues?.[fieldName] ?? []]);
            }
            return;
        }
        else if(typeof this[fieldName] === 'object'){
            console.warn('We not support object for now... you need make code self.. sorry for now ;-)')
            return;
        }

        this[fieldName] = this._defaultValues?.[fieldName] ?? undefined;
    }
    compare(fieldName){
        if(Array.isArray(this[fieldName])){
            if(this?.[fieldName].length !== this._orgValues?.[fieldName].length){
                return false;
            }

           return stringify(this?.[fieldName]) === stringify(this._orgValues?.[fieldName])
        }
        else if(typeof this[fieldName] === 'object'){
            return stringify(this?.[fieldName]) === stringify(this._orgValues?.[fieldName])
        }

        return this?.[fieldName] === this._orgValues?.[fieldName];
    }
    compareDefault(fieldName){
        return this?.[fieldName] === this._defaultValues[fieldName];
    }

    static setupFunction(anyData = undefined){
        // [type, classType, field, defaultValue, customOutputFilter, anyData]

        function defaultAnyDataF(override){
            if(anyData === undefined){
                return undefined;
            }

            return {...anyData, ...override};
        }

        return {
            setSymbioteItem(name, layout, output = undefined){
                return [
                    Object,
                    class extends Symbiote{
                        constructor(p,r) {
                            super(p,layout, r);
                        }
                    },
                    'symbiote_' + name,
                    undefined,
                    output ? output : undefined,
                ]
            },
            setString(field, override = {}, anyData = {}){
                if(typeof override === "string"){
                    override = {default:override};
                }

                // [type, classType, field, defaultValue, customOutputFilter, anyData]

                return [
                    String, // Type
                    undefined, //ClassType
                    field, // field
                    override?.default ?? undefined, //Default value
                    override?.filter ?? setIfEmpty, // customOutputFilter
                    defaultAnyDataF(anyData) // anyData
                ];
            },
            setBoolean(field, override = {}, anyData = {}){
                if(typeof override === "boolean"){
                    override = {default:override};
                }

                return [
                    Boolean,
                    undefined,
                    field,
                    override?.default ?? undefined,
                    override?.filter ?? undefined,
                    defaultAnyDataF(anyData)
                ]
            },
            setInt(field, override = {}, anyData = {}){
                if(typeof override === "number"){
                    override = {default:override};
                }

                return [
                    Int,
                    undefined,
                    field,
                    override?.default ?? undefined,
                    override?.filter ?? undefined,
                    defaultAnyDataF(anyData)
                ];
            },
            setNumber(field, override = {}, anyData = {}){
                if(typeof override === "number"){
                    override = {default:override};
                }

                return [
                    Number,
                    undefined,
                    field,
                    override?.default ?? undefined,
                    override?.filter ?? undefined,
                    defaultAnyDataF(anyData)
                ];
            },
            setObject(field, override = {}, anyData = {}){
                return [
                    Object,
                    override?.classType ?? undefined,
                    field,
                    override?.default ?? {},
                    override?.filter ?? undefined,
                    defaultAnyDataF(anyData)
                ];
            },
            setClass(field, classType, anyData = {}, defaultObjectValue = undefined){
                if(defaultObjectValue === 'autodetect'){
                    const extendedClass = Object.getPrototypeOf(classType.prototype).constructor?.name ?? 'object';

                    switch (extendedClass){
                        case 'listItemsObject':
                        case 'listItemsDefault':
                        case 'Array':
                            defaultObjectValue = [];
                            break;
                        case 'object':
                        default:
                            defaultObjectValue = {};
                            break;
                    }
                }

                return [
                    Object,
                    classType,
                    field,
                    defaultObjectValue,
                    setIfEmptyObject,
                    defaultAnyDataF(anyData)
                ];
            },
            setArray(field, classType, anyData){
                return [
                    Array,
                    classType,
                    field,
                    undefined,
                    undefined,
                    defaultAnyDataF(anyData)
                ];
            }
        }
    }
}
class DefaultSettingsItem extends DefaultSettingsBase{
    _hidden = false;
    constructor(params, layout, _name, enableType = false, root) {
        super();
        this.init(params, layout, _name, enableType, root)
    }

    toggle(){
        this._hidden = !this._hidden;
    }
}
class Symbiote extends DefaultSettingsItem{
    _clean(){
        this._defaultValues = {}
        this._outputKeys = {}
        this._outputFilter = {}
        this._layout = {}
        this._props = {}
        this._type = {}
        this._anyData = {}
        this._orgValues = {}
    }
    updateLayoutInstance(layout){
        this._clean();
        this.init(this._params, layout, undefined, false, this.root)
    }
}
class DefaultSettingsComponent extends DefaultSettingsBase{
    version = 1
    _version = 1
}


function EasyDefaultSettingsItem(name, layout, outputFilter = undefined, validate = undefined){
    return class extends DefaultSettingsItem{
        constructor(params) {
            super(params, layout, name);
        }

        output() {
            if(outputFilter !== undefined && typeof outputFilter === "function"){
                return outputFilter(super.output());
            }

            return super.output();
        }

        validate(){
            if(validate !== undefined && typeof validate === "function"){
                return validate(this);
            }
        }
    }
}

function EasyDefaultSimpleItem(name, type){
    return class extends BaseArray {
        _hidden = true;
        constructor(params) {
            super();

            if(params === undefined){
                return this;
            }

            Object.entries(params).forEach(([_key, value]) => {
                this.push({_key,...value});
            });

            if(this.length){
                this._hidden = false;
            }
        }

        push(...items) {
            items.forEach((item) => {
                super.push(new type(item));
            });
        }

        output(){
            // output as object with _key as key
            return this
                .filter((item) => item.validate())
                .reduce((acc, item) => {
                    if(item['_key'] === undefined){
                        return acc;
                    }

                    acc[item['_key']] = item.output();

                    return acc;
                }, {});
        }
    }
}

/**
 * NOt sure this is good idea or not!!!
 * @param name
 * @param type
 * @return
 */
function EasyDefaultSettingsListItems(name, type){
    return class extends BaseArray {
        _hidden = true;
        constructor(params) {
            super();
            if (params === undefined){
                return this;
            }

            Object.entries(params).forEach(([_key, value]) => {
                value.forEach((item) => {
                    this.push(({_key,...item}));
                });
            });

            if(this.length){
                this._hidden = false;
            }
        }

        push(...items) {
            items.forEach((item) => {
                super.push(new type(item));
            });
        }

        output(){
            // output as object with _key as key
            return this
                .filter((item) => item.validate())
                .reduce((acc, item) => {
                    if(acc?.[item?.['_key']] === undefined){
                        acc[item['_key']] = [];
                    }

                    acc[item['_key']].push(item.output());

                    return acc;
                }, {});
        }
    }
}

class listItemsDefault extends BaseArray {
    type = undefined;
    root = undefined;
    constructor(type, params, root) {
        super();
        this.type = type;
        this.root = root;

        if(params === undefined){
            return this;
        }

        // it is object so we need to change to array
        if(Array.isArray(params)){
            params.forEach((item) => {
                this.push(item);
            });
        }
    }

    push(...items) {
        items.forEach((item) => {
            super.push(new this.type(item, this.root));
        });
    }

    output(){
        return this.length > 0 ?
            this
                .filter((item) => item.validate())
                .map((item) => item.output())
            : undefined;
    }
}
class listItemsObject extends BaseArray {
    type = undefined;
    root = undefined;
    constructor(type, params, root) {
        super();
        this.type = type;
        this.root = root;

        if(params === undefined){
            return this;
        }
        else if(typeof params === "object" && Array.isArray(params)){
            return this;
        }
        else if(typeof params === "object"){
            Object.entries(params).forEach(([_key, value]) => {
                if(value !== undefined){
                    if(typeof value === "object"){
                        this.push({
                            _key,
                            ...value
                        });
                    }
                    else {
                        this.push({
                            _key,
                            _value: value,
                        });
                    }
                }
            });
        }
    }

    push(...items) {
        items.forEach((item) => {
            console.log("listItemsObject::push", item);
            super.push(new this.type(item, this.root));
        });
    }

    output(){
        return this.length > 0 ?
            this
                .filter((item) => item.validate())
                .reduce((acc, item) => {
                    acc[item['_key']] = item.output();
                    return acc;
                }, {})
            : undefined;
    }
}

class BasicSimpleKeyValue extends DefaultSettingsItem{
    index = 0;
    constructor(params, root) {
        // params, layout, _name, enableType = false, root
        super(params, {
            key:          [String, undefined,  '_key',    undefined, setIfEmpty],
            value:        [String, undefined,  '_value',  undefined, setIfEmpty],
        }, 'BasicSimpleKeyValue', false, root);
    }

    validate(){
        return this.key !== undefined ? this.key : false;
    }
}
class BasicSimpleItemsKeyValue extends listItemsObject{
    constructor(params, root) {
        super(BasicSimpleKeyValue, params, root);
    }
    output() {
        return this.reduce((acc, item) => {
            const data = item.output();

            // think it will work for now
            if(data === undefined){
                return acc;
            }


            acc[data._key] = data._value;
            return acc;
        }, {})
    }
}


/**
 * Widget classes for settings
 */

class Widget extends DefaultSettingsItem{
    constructor(params, layout, name, root) {
        super(params, layout, name, true, root);
    }
}

class CarShopShareText extends DefaultSettingsItem{
    constructor(params) {
        super(params,{
            driver:                     [String, undefined, 'driver', undefined, setIfEmpty],
            extra_driver:               [String, undefined, 'extradriver', undefined, setIfEmpty],
            cta_extra_driver:           [String, undefined, 'ctaextradriver', undefined, setIfEmpty],
            start:                      [String, undefined, 'start', undefined, setIfEmpty],
            end:                        [String, undefined, 'end', undefined, setIfEmpty],
            note:                       [String, undefined, 'note', undefined, setIfEmpty],
            location:                   [String, undefined, 'location', undefined, setIfEmpty],
            abroad:                     [String, undefined, 'abroad', undefined, setIfEmpty],
            upload:                     [String, undefined, 'upload', undefined, setIfEmpty],
            license_front:              [String, undefined, 'license_front', undefined, setIfEmpty],
            license_front_completed:    [String, undefined, 'license_front_completed', undefined, setIfEmpty],
            license_back:               [String, undefined, 'license_back', undefined, setIfEmpty],
            license_back_completed:     [String, undefined, 'license_back_completed', undefined, setIfEmpty],
            health_front:               [String, undefined, 'health_front', undefined, setIfEmpty],
            health_front_completed:     [String, undefined, 'health_front_completed', undefined, setIfEmpty],
            th_upfront:                 [String, undefined, 'th_upfront', undefined, setIfEmpty],
            td_first_month:             [String, undefined, 'td_first_month',undefined, setIfEmpty],
            th_monthly:                 [String, undefined, 'th_monthly', undefined, setIfEmpty],
            td_extra_driver:            [String, undefined, 'td_extradriver', undefined, setIfEmpty],
            td_abroad:                  [String, undefined, 'td_abroad', undefined, setIfEmpty],
            td_price_milage:            [String, undefined, 'td_price_milage', undefined, setIfEmpty],
            td_price_optionals:         [String, undefined, 'td_price_optionals', undefined, setIfEmpty],
            cta_submit:                 [String, undefined, 'cta_submit', undefined, setIfEmpty],
            // added by @ulrik
            milage_dropdown:            [String, undefined, 'milage_dropdown', undefined, setIfEmpty],
            company:                    [String, undefined, 'company', undefined, setIfEmpty],
            select_date:                [String, undefined, 'select_date', undefined, setIfEmpty],
            cancel_date:                [String, undefined, 'cancel_date', undefined, setIfEmpty],
            td_price:                   [String, undefined, 'td_price', undefined, setIfEmpty],
            td_optional_upfront:        [String, undefined, 'td_optional_upfront', undefined, setIfEmpty],
            optionals:                  [String, undefined, 'optionals', undefined, setIfEmpty],
            td_total:                   [String, undefined, 'td_total', undefined, setIfEmpty],
            td_extradriver:             [String, undefined, 'td_extradriver', undefined, setIfEmpty],
            td_price_md:                [String, undefined, 'td_price_md', undefined, setIfEmpty],
            td_total_md:                [String, undefined, 'td_total_md', undefined, setIfEmpty],
        });
    }
}
class CalculationTemplatesItem extends DefaultSettingsItem{
    constructor(params) {
        super(params,{
            _key: [Int, undefined, '_key', undefined],
            key: [String, undefined, 'key', undefined],
            label: [String, undefined, 'label', undefined],
            value: [String, undefined, 'value', undefined],
        });
    }

    validate(){
        return (this.key !== undefined);
    }

    output() {
        return {
            ...super.output(),
            _key:undefined
        }
    }
}

class WidgetContactFormsItemSettings extends DefaultSettingsItem{
    constructor(params) {
        super(params,{
            _key:                   [String, undefined, '_key', undefined],
            label:                  [String, undefined, 'l', undefined],
            side_bar:               [Boolean, undefined, 'sb', false],
            side_bar_image:         [String, undefined, 'sb_image', undefined],
            side_bar_min_height :   [String, undefined, 'sb_m_height', undefined],
        });
    }

    validate(){
        return (
            this.label !== undefined ||
            this.side_bar !== undefined ||
            this.side_bar_image !== undefined ||
            this.side_bar_min_height !== undefined
        );
    }

    output() {
        return {
            ...super.output(),
            _key:undefined
        }
    }
}

class WidgetContact extends Widget{
    constructor(params, root) {
        // foreach key in params and skip (l, sb, sb_image, sb_min_height)

        let basicParams = {};
        let newParams = {};


        for(let key in params){
            if(key === 'l' || key === 'sb' || key === 'sb_image' || key === 'sb_m_height'){
                basicParams[key] = params?.[key];
                continue;
            }

            newParams[key] = params?.[key];
        }

        basicParams['params'] = newParams;

        console.log('WidgetContact', basicParams);

        super(basicParams, {
            label:                  [String, undefined, 'l', undefined],
            side_bar:               [Boolean, undefined, 'sb', false],
            side_bar_image:         [String, undefined, 'sb_image', undefined],
            side_bar_min_height :   [String, undefined, 'sb_m_height', undefined],
            data:                   [Object, EasyDefaultSimpleItem('params', WidgetContactFormsItemSettings), 'params', undefined, undefined],
        });
    }

    output(){
        let output = super.output();
        console.log("output", output);
        let params = output?.params ?? {};

        if(params !== undefined){
            delete output.params;
            for (let key in params){
                output[key] = params[key];
            }
        }

        return output
    }
}




class Page extends DefaultSettingsItem{
    constructor(params) {
        super(params, {
            id: [Int, undefined, 'id', undefined],
            type: [String, undefined, 'type', undefined],
        });
    }

    output() {
        // check if id is number and if not return undefined
        if(this.id === undefined || this.id === ''){
            return undefined;
        }

        return {
            id: Number(this.id),
            type: this.type
        }
    }
}

class SearchBanner extends DefaultSettingsItem{
    constructor(params) {
        super(params, {
            bottom_place:   [String,    undefined,  'bottom_place', 'center'],
            bottom_full:    [Boolean,   undefined,  'bottom_full',  false],
        });
    }
}

class SearchQuickInfo extends DefaultSettingsItem{
    constructor(params) {
        super(params, {
            place:          [String,    undefined,  'place',    'top'],
            full:           [Boolean,   undefined,  'full',     false],
            rounded:        [Boolean,   undefined,  'rounded',  true],
        });
    }
}

class Search extends DefaultSettingsItem{
    constructor(params) {
        super(params, {
            banner:         [Object,    SearchBanner,       'banner',           undefined],
            quick_info:     [Object,    SearchQuickInfo,    'quick_info',       undefined],
            disable_price:  [Boolean,   undefined,          'disablePrice',     false],
            compare_on:     [Boolean,   undefined,          'compare',          false],
            tax:            [String,    undefined,          'tax',              undefined]
        });
    }
}
class filters extends listItemsDefault {
    constructor(params, root) {
        super(filterItem, params, root);
    }
}
class filterItem extends DefaultSettingsItem{
    constructor(params, root) {
        // check if f is object

        if(root._version === 1 && params?.f !== undefined){
            let newValue = undefined;

            if(typeof params?.f === 'string' || typeof params?.f === 'object' && Array.isArray(params?.f)){
                const format = typeof params?.f === 'string' ? params?.f : (params?.f?.[0] ?? undefined);
                const args = typeof (params?.f) === 'string' ? [] : (params?.f?.slice(1) ?? []);

                switch (format){
                    case 'int':
                        newValue = '${parseInt(this.value)}';
                        break;
                    case 'float':
                        newValue = '${parseFloat(this.value)}';
                        break;
                    case 'format':
                        newValue = '${this.number(this.value, 0, ",", ".")}';
                        break;
                    case 'currency':
                        newValue = '${this.currency(this.value, true, {decimals:0})}';
                        if(args.length > 0){
                            let priceFormat = "{}";
                            try{
                                priceFormat = args[1] ? JSON.stringify(args[1]) : "{}";
                            }
                            catch(e){
                                priceFormat = "{}"
                            }

                            newValue = `$\{this.currency(this.value, ${args[0] ?? true}, ${priceFormat})}`;
                        }
                        break;
                    case 'percent':
                        newValue = '${parseInt(this.value).toFixed(2)} %';
                        break;
                }
            }

            params.f = undefined;
            params.o  = newValue;
            root.version = 2;
        }

        // type, classType, field, defaultValue, customOutputFilter, anyData] = layout[key];
        super(params, {
            key:        [String,    undefined,      'k', undefined],
            size:       [Int,       undefined,      's', 3],
            label:      [String,    undefined,      'l', undefined],
            mobile:     [Int,       undefined,      'm', 1],// if 0 = hidden on mobile, 1 = show on mobile(default)
            format:     [Mixed,     undefined,      'o', undefined],
        });
    }

    validate(){
        return (
            this.key !== undefined &&
            this.size !== undefined &&
            this.label !== undefined
        );
    }

    output(){
        let o = super.output();

        if(o.k === "mobile"){
            return {
                k: "mobile",
                l: o.l,
            }
        }

        if(o?.f?.startsWith('[') && o?.f?.endsWith(']')){
            try{
                o.f = JSON.parse(o.f);
            }
            catch (e) {
                console.log('error in filterItem output');
            }
        }

        return o;
    }
}
class pricePriorities extends BaseArray {
    _hidden = true;
    constructor(params) {
        super();
        // check if params?.priorities is list (Array)
        if(params === undefined){
            return this;
        }

        if (Array.isArray(params)) {
            params.forEach((item) => {
                this.push(item);
            });

            if(params.length){
                this._hidden = false;
            }
        }
        else {
            console.info("pricePriorities is not an array");
        }
    }

    push(...items) {
        items.forEach((item) => {
            let value = Int(item);
            if(typeof value === "number" && !isNaN(value)){
                // if value is defined in list not allow
                if(!this.find((item) => { return item === value})){
                    super.push(value);
                }
            }
        });
    }

    output(){
        if(this.length > 0){
            const data = this.filter((value, index) => this.indexOf(value) === index);

            return data.length > 0 ? data : undefined;
        }

        return undefined;
    }

    toggle(){
        this._hidden = !this._hidden;
    }
}
class CarParts extends listItemsDefault {
    constructor(params, root) {
        super(CarPartItem, params, root);
    }
}

class CarPartItem {
    /**
     * @type {string|undefined}
     * required
     *
     * in json it is t
     */
    type = undefined
    /**
     * @type {Widget|CustomJson|undefined}
     * optional
     *
     * in json it is s
     */
    settings = undefined
    /**
     * @type {string|undefined}
     */
    rules = undefined

    root = undefined;

    constructor(params, root) {
        this.type = params?.t ? String(params?.t) : undefined;
        this.rules = params?.r ? String(params?.r) : undefined;
        this.root = root;
        this.setSettingsType(params?.t, params?.s ?? {});
        outputNonDefinedProperties(params, this, "CarPartItem", {
            t: "type",
            s: "settings",
            r: "rules"
        });
    }

    setSettingsType(type, settings){
        const root = this.root;

        if(type === 'hr' || type === undefined){
            this.settings = undefined
            return;
        }

        const defaultAnyData = {
            namespace:type.toString().toLowerCase(),
            description:false
        };

        function defaultAnyDataF(override = {}){
            console.log('defaultAnyDataF', {...defaultAnyData, ...override});
            return {...defaultAnyData, ...override};
        }

        const ig = IntroGroups;

        function defaultString(field, override = {}, anyData = {}){
            if(typeof override === "string"){
                override = {default:override};
            }

            // [type, classType, field, defaultValue, customOutputFilter, anyData]

            return [
                String, // Type
                undefined, //ClassType
                field, // field
                override?.default ?? undefined, //Default value
                override?.filter ?? setIfEmpty, // customOutputFilter
                defaultAnyDataF(anyData) // anyData
            ];
        }

        function defaultBoolean(field, override = {}, anyData = {}){
            if(typeof override === "boolean"){
                override = {default:override};
            }

            return [
                Boolean,
                undefined,
                field,
                override?.default ?? undefined,
                override?.filter ?? undefined,
                defaultAnyDataF(anyData)
            ]
        }

        function defaultInt(field, override = {}, anyData = {}){
            if(typeof override === "number"){
                override = {default:override};
            }

            return [
                Int,
                undefined,
                field,
                override?.default ?? undefined,
                override?.filter ?? undefined,
                defaultAnyDataF(anyData)
            ];
        }

        function defaultNumber(field, override = {}, anyData = {}){
            if(typeof override === "number"){
                override = {default:override};
            }

            return [
                Number,
                undefined,
                field,
                override?.default ?? undefined,
                override?.filter ?? undefined,
                defaultAnyDataF(anyData)
            ];
        }

        function defaultObject(field, override = {}, anyData = {}){
            return [
                Object,
                override?.classType ?? undefined,
                field,
                override?.default ?? {},
                override?.filter ?? undefined,
                defaultAnyDataF(anyData)
            ];
        }

        const defaultOrder = {
            type: 'select',
            tooltip: true,
            options:{
                left: 'left',
                right: 'right',
            }
        };

        const defaultSize = {
            type: 'select',
            tooltip: false,
            options:{
                '1/2': '1-2',
                '1/3': '1-3',
                '2/3': '2-3',
                '1/4': '1-4',
                '3/4': '3-4',
                '1/5': '1-5',
                '2/5': '2-5',
                '3/5': '3-5',
                '4/5': '4-5',
                'auto': 'auto',
                'full' : 'full',
            }
        }

        switch (type) {
            case 'description':
            case 'description2':
                this.settings = new Widget(settings, {
                    is:                 defaultBoolean('is', false, {description:true}),
                    header_label:       defaultString('l',{}, {description:true}),
                    content_field:      defaultString('f', undefined, {tooltip: true}),
                    field_2:            defaultString('f2', undefined, {tooltip: true}),
                    css_class:          defaultString('class'),
                    aside_phyron:       defaultString('phyron', undefined, {tooltip: true}),
                    aside_video:        defaultString('video', undefined, {tooltip: true}),
                    aside_video_image:  defaultString('video_image', undefined, {tooltip: true}),
                    aside_ul:           defaultString('ul', undefined, {tooltip: true}),
                    aside_ul_2:         defaultString('ul2', undefined, {tooltip: true}),
                    aside_field:        defaultString('af', undefined, {tooltip: true}),
                    aside_field_2:      defaultString('af2', undefined, {tooltip: true}),
                    aside_cta:          defaultString('cta', undefined, {tooltip: true}),
                    aside_cta_2:        defaultString('cta2', undefined, {tooltip: true}),
                    aside_table:        defaultString('table', undefined, {tooltip: true}),
                    aside_table_2:      defaultString('table2', undefined, {tooltip: true}),
                    aside_image:        defaultString('img', undefined, {tooltip: true}),
                    aside_profile:      defaultString('pf', undefined, {tooltip: true}),
                }, type, root);
                break;
            case 'info':
                this.settings = new Widget(settings,{
                    a:                  defaultBoolean('a', true, {tooltip: true}),
                    icons:              defaultBoolean('i', false, {tooltip: true}),
                    rows:               defaultInt('c', 4),
                    slt:                defaultString('slt', undefined, {tooltip: true}),
                    smt:                defaultString('smt', undefined, {tooltip: true}),
                    r:                  defaultString('r'),
                    p:                  defaultString('p', 'l', {type: 'select',tooltip: false,options:{'l': 'left','c': 'center','r': 'right'}}),
                    groups:             defaultObject('g', {classType:ig}),
                }, type, root);
                break;
            case 'infoShort':
                this.settings = new Widget(settings,{
                    css_class:          defaultString('class'),
                    col:                defaultString('c'),
                    groups:             defaultObject('g', {classType:ig}),
                }, type, root);
                break;
            case 'infoSlider':
                this.settings = new Widget(settings,{
                    header_label:       defaultString('l'),
                    css_class:          defaultString('class'),
                    groups:             defaultObject('g', {classType:ig}),
                }, type, root);
                break;
            case 'equipment':
                this.settings = new Widget(settings, {
                    header_label:       defaultString('l'),
                    i :                 defaultBoolean('i', false, {tooltip: true}),
                    tooltip:            defaultBoolean('tooltip', false, {tooltip: true}),
                }, type, root);
                break;
            case 'stage':
                this.settings = new Widget(settings,{
                    header_label:       defaultString('l'),
                    css_class:          defaultString('class'),
                    size:               defaultString('s', '1/2', defaultSize),
                    order:              defaultString('order', 'left', defaultOrder),
                    content_field:      defaultString('f', undefined, {tooltip: true}),
                    profile:            defaultString('pf', undefined, {tooltip: true}),
                    table:              defaultString('table', undefined, {tooltip: true}),
                    ul:                 defaultString('ul', undefined, {tooltip: true}),
                    field_2:            defaultString('f2', undefined, {tooltip: true}),
                    table_2:            defaultString('table2', undefined, {tooltip: true}),
                    ul_2:               defaultString('ul2', undefined, {tooltip: true}),
                    cta:                defaultString('cta', undefined, {tooltip: true}),
                    cta_2:              defaultString('cta2', undefined, {tooltip: true}),
                    aside_field:        defaultString('af', undefined, {tooltip: true}),
                    aside_profile:      defaultString('apf', undefined, {tooltip: true}),
                    aside_table:        defaultString('atable', undefined, {tooltip: true}),
                    aside_ul:           defaultString('aul', undefined, {tooltip: true}),
                    aside_phyron:       defaultString('phyron', undefined, {tooltip: true}),
                    aside_video:        defaultString('video', undefined, {tooltip: true}),
                    aside_video_image:  defaultString('video_image', undefined, {tooltip: true}),
                    aside_image360:     defaultString('img360', undefined, {tooltip: true}),
                    aside_image:        defaultString('img', undefined, {tooltip: true}),
                    aside_field_2:      defaultString('af2', undefined, {tooltip: true}),
                    aside_cta:          defaultString('acta', undefined, {tooltip: true}),
                }, type, root);
                break;
            case 'video':
                this.settings = new Widget(settings,{
                    header_label:       defaultString('l'),
                    css_class:          defaultString('class'),
                    video:              defaultString('video', undefined, {tooltip: true}),
                    video_image:        defaultString('video_image', undefined, {tooltip: true}),
                }, type, root);
                break;
            case 'custom_calculation':
                this.settings = new Widget(settings, {
                    header_label:       defaultString('l'),
                }, type, root);
                break;
            case 'contentSlider':
                this.settings = new Widget(settings,{
                    header_label:       defaultString('l'),
                    field:              defaultString('f', undefined, {tooltip: true}),
                    type:               defaultString('t', undefined, {type: 'select',tooltip: false,options:{'reviews': 'reviews','employees': 'employees','associates': 'associates'}}),
                    css_class:          defaultString('class'),
                    aside_field:        defaultString('af', undefined, {tooltip: true}),
                    size:               defaultString('s', '1/2', defaultSize),
                    order:              defaultString('order', 'left', defaultOrder),
                    image:              defaultString('img', undefined, {tooltip: true}),
                    small_devices:      defaultInt('sm',1),
                    medium_devices:     defaultInt('md',2),
                    large_devices:      defaultInt('lg',3),
                }, type, root);
                break;
            case 'contact':
                this.settings = new WidgetContact(settings, root);
                break;
            case 'cars':
                this.settings = new Widget(settings, {
                    header_label:       [String,  undefined, 'l',           undefined,      setIfEmpty,     defaultAnyData]
                }, type, root);
                break;
            case 'department':
                /** No one of them i have copy of have config it why i set this to undefined */
                this.settings = undefined;
                break;
            case 'santander':
                this.settings = new Widget(settings,{
                    header_label:       defaultString('l'),
                    css_class:          defaultString('class'),
                    size:               defaultString('s', '1/2', defaultSize),
                    order:              defaultString('order', 'left', defaultOrder),
                    key_id:             defaultString('id', undefined, {tooltip: true}),
                }, type, root);
                break;
            case 'elfsight':
                this.settings = new Widget(settings,{
                    header_label:       defaultString('l'),
                    css_class:          defaultString('class'),
                    key_id:             defaultString('id', undefined, {tooltip: true}),
                }, type, root);
                break;
            case 'miniLeasing':
                this.settings = new Widget(settings,{
                    header_label:       defaultString('l'),
                    sh:                 defaultString('sh', '100px', {tooltip: false}),
                    input_layout:       defaultString('il', 'floating', {tooltip: true}),
                    size:               defaultString('s', '1/2', defaultSize),
                    order:              defaultString('order', 'left', defaultOrder),
                    abroad:             defaultBoolean('abroad', false),
                    txt:                [TextObject, CarShopShareText,      'txt',      undefined,  undefined,  defaultAnyData],
                }, type, root);
                break;
            case 'leasinginfo':
                this.settings = new Widget(settings,{
                    toggle:             defaultBoolean('toggle',true, {tooltip: false}),
                    residual:           defaultBoolean('residual',true, {tooltip: true}),
                    alt_total:          defaultBoolean('alt_total',false, {tooltip: true}), // @TODO maybe remove that - is not used in file ?. later
                    mileage:            defaultBoolean('mileage',false, {tooltip: true}),
                    header_label:       defaultString('l'),
                    id:                 defaultString('id', undefined, {tooltip: true}),
                    mileage_static:     defaultNumber('mileage_static',  -1, {tooltip: true}),
                    alt_total_extra:    defaultNumber('alt_total_extra', 0, {tooltip: true}),
                    text:               defaultString('txt', undefined, {tooltip: true}),
                }, type, root);
                break;
            case 'fakeCalculation':
                this.settings = new Widget(settings,{
                    css_class:          defaultString('class'),
                    size:               defaultString('s', '1/2', defaultSize),
                    order:              defaultString('order', 'left', defaultOrder),
                    header_label:       defaultString('l'),
                    reset_btn_label:    defaultString('resetBtnLabel', undefined, {tooltip: true}),
                    submit_btn_label:   defaultString('submitBtnLabel', undefined, {tooltip: true}),
                    box_header_label:   defaultString('boxHeaderLabel', undefined, {tooltip: true}),
                    no_price_label:     defaultString('noPriceLabel', undefined, {tooltip: true}),
                    type_btn:           defaultBoolean('typeBtn',true),
                    audience_btn:       defaultBoolean('audienceBtn',true),
                    duration_btn:       defaultBoolean('durationBtn',true),
                    reset_btn:          defaultBoolean('resetBtn',true),
                }, type, root);
                break;
            case 'caradsCalculator':
                this.settings = new Widget(settings,{
                    header_label:       defaultString('l'),
                    price_path:         defaultString('pricePath', 'pricing.minilease.simple', {tooltip: true}),
                    down_payment:       defaultBoolean('downpayment', false, {tooltip: false}),
                    upfront:            defaultBoolean('upfront', true, {tooltip: false}),
                    duration:           defaultBoolean('duration', true, {tooltip: false}),
                    total:              defaultBoolean('total', true, {tooltip: false}),
                    obs_duration:       defaultBoolean('obsDuration', true, {tooltip: false}),
                    txt: [TextObject, EasyDefaultSettingsItem('carads_calculator_txt', {
                        label:              defaultString('label', 'Antal kilometer pr. år'),
                        slider_label:       defaultString('slider_label', 'Angive kilometer pr. år'),
                        obs:                defaultString('obs', undefined, {tooltip: true}),
                        obs2:               defaultString('obs2', undefined, {tooltip: true}),
                        submit:             defaultString('submit', undefined, {tooltip: true}),
                        current_monthly:    defaultString('current_monthly', undefined, {tooltip: true}),
                        duration:           defaultString('duration', undefined, {tooltip: true}),
                        upfront:            defaultString('upfront', undefined, {tooltip: true}),
                        total:              defaultString('total', undefined, {tooltip: true}),
                    }), 'txt', undefined],
                }, type, root);
                break;
            case 'customCalculation':
                this.settings = new Widget(settings,{
                    header_label:           defaultString('l'),
                }, type, root);
                break;
            case 'nextEngine':
                this.settings = new Widget(settings,{
                    header_label:       defaultString('l'),
                    css_class:          defaultString('class'),
                    order:              defaultString('order', 'left', defaultOrder),
                    field:              defaultString('f', undefined, {tooltip: true}), // @test need to be tested - fun
                    table:              defaultString('table', undefined, {tooltip: true}), // @test need to be tested - fun
                    ul:                 defaultString('ul', undefined, {tooltip: true}), // @test need to be tested - fun
                    field_2:            defaultString('af', undefined, {tooltip: true}), // @test need to be tested - fun
                    cta:                defaultString('cta', undefined, {tooltip: true}), // @test need to be tested - fun
                    video:              defaultString('video', undefined, {tooltip: true}), // @test need to be tested - fun
                    video_image:        defaultString('video_image', undefined, {tooltip: true}), // @test need to be tested - fun
                    image:              defaultString('img', undefined, {tooltip: true}), // @test need to be tested - fun
                }, type, root);
                break;
            case 'trustpilot':
                this.settings = new Widget(settings,{
                    header_label:       defaultString('l'),
                    css_class:          defaultString('class'),
                    template_id:        defaultString('t_id', undefined, {tooltip: true}),
                    businessunit_id:    defaultString('b_id', undefined, {tooltip: true}),
                    area_type:          defaultString('t', undefined, {tooltip: true}),
                }, type, root);
                break;
            case 'klimaapi':
                this.settings = new Widget(settings,{
                    header_label:       defaultString('l'),
                    css_class:          defaultString('class'),
                    size:               defaultString('s', '1/2', defaultSize),
                    order:              defaultString('order', 'left', defaultOrder),
                    key_id:             defaultString('id', undefined, {tooltip: true}),
                    type:               defaultString('t', undefined, {type: 'select',tooltip: true,options:{'range': 'range','saving': 'saving','saving-premium': 'saving-premium','saving-popup': 'saving-popup'}}),
                    width:              defaultString('w'),
                    color:              defaultString('c'),
                    placement:          defaultString('p'),
                    field:              defaultString('f', undefined, {tooltip: true}),
                    ul:                 defaultString('ul', undefined, {tooltip: true}),
                    table:              defaultString('table', undefined, {tooltip: true}),
                    cta:                defaultString('cta', undefined, {tooltip: true}),
                    video:              defaultString('video', undefined, {tooltip: true}),
                    video_image:        defaultString('video_image', undefined, {tooltip: true}),
                    image:              defaultString('img', undefined, {tooltip: true}),
                }, type, root);
                break;
            case 'klimaapi2':
                this.settings = new Widget(settings,{
                    header_label:       defaultString('l'),
                    css_class:          defaultString('class'),
                    size:               defaultString('s', '1/2', defaultSize),
                    order:              defaultString('order', 'left', defaultOrder),
                    key_id:             defaultString('id', undefined, {tooltip: true}),
                    popup:              [Boolean,   undefined, 'popup',             true,       undefined,      defaultAnyData],
                    adress:             [Boolean,   undefined, 'adress',            undefined,      undefined,      defaultAnyData],
                    search:             [Boolean,   undefined, 'search',            undefined,      undefined,      defaultAnyData],
                    contactinfofirst:   [Boolean,   undefined, 'contactinfofirst',  undefined,      undefined,      defaultAnyData],
                    hidestartscreen:    [Boolean,   undefined, 'hidestartscreen',   undefined,       undefined,      defaultAnyData],
                    affinity:           defaultString('affinity'),
                    tracker:            defaultString('tracker'),
                    trackerid:          defaultString('trackerid'),
                    customtracking:     defaultString('customtracking'),
                    customcarimage:     defaultString('customcarimage'),
                    brandcolor:         defaultString('brandcolor'),
                    placement:          defaultString('placement','left', defaultOrder),
                    field:              defaultString('f', undefined, {tooltip: true}),
                    ul:                 defaultString('ul', undefined, {tooltip: true}),
                    table:              defaultString('table', undefined, {tooltip: true}),
                    cta:                defaultString('cta', undefined, {tooltip: true}),
                    video:              defaultString('video', undefined, {tooltip: true}),
                    video_image:        defaultString('video_image', undefined, {tooltip: true}),
                    image:              defaultString('img', undefined, {tooltip: true}),
                }, type, root);
                break;
            case 'autoitevdata':
                this.settings = new Widget(settings,{
                    header_label:       defaultString('l'),
                    size:               defaultString('s', '1/2', defaultSize),
                    order:              defaultString('order', 'left', defaultOrder),
                    key_id:             defaultString('id', undefined, {tooltip: true}),
                    text_colors:        defaultString('textcolors',        {default:'#911abc', filter:setColorValue}, {type:'color'}),
                    icon_outline_color: defaultString('iconoutlinecolor',  {default:'#911abc', filter:setColorValue}, {type:'color'}),
                    icon_fill_color:    defaultString('iconfillcolor',     {default:'#911abc', filter:setColorValue}, {type:'color'}),
                    background_color:   defaultString('backgroundcolor',   {default:'#911abc', filter:setColorValue}, {type:'color'}),
                    aside_field:        defaultString('f', undefined, {tooltip: true}),
                    aside_table:        defaultString('table', undefined, {tooltip: true}),
                    aside_ul:           defaultString('ul', undefined, {tooltip: true}),
                    aside_cta:          defaultString('cta', undefined, {tooltip: true}),
                    aside_video:        defaultString('video', undefined, {tooltip: true}),
                    aside_video_image:  defaultString('video_image', undefined, {tooltip: true}),
                    aside_image:        defaultString('img', undefined, {tooltip: true}),
                }, type, root);
                break;
            case 'autoitevsaving':
                this.settings = new Widget(settings,{
                    header_label:       defaultString('l'),
                    size:               defaultString('s', '1/3', defaultSize),
                    order:              defaultString('order', 'left', defaultOrder),
                    type:               defaultString('t', 'inline', {type: 'select',tooltip: false,options:{'inline': 'inline','popup': 'popup'}}),
                    key_id:             defaultString('id', undefined, {tooltip: true}),
                    color_variant_1:    defaultString('colorvariant1', {default:'#202F16',filter:setColorValue}, {type:'color'}),
                    color_variant_2:    defaultString('colorvariant2', {default:'#202F16',filter:setColorValue}, {type:'color'}),
                    color_variant_3:    defaultString('colorvariant3', {default:'#B99931',filter:setColorValue}, {type:'color'}),
                    primary_color:      defaultString('primarycolor',  {default:'#B99931',filter:setColorValue}, {type:'color'}),
                    text_colors:        defaultString('textcolors',    {default:'#FFFFFF',filter:setColorValue}, {type:'color'}),
                    popup_location:     defaultString('popuplocation', 'bottom-left', {type: 'select',tooltip: false,options:{'bottom-left': 'bottom-left','bottom-right': 'bottom-right'}}),
                    popup_title:        defaultString('popuptitle', 'Skift til elbil og spar'),
                    popup_text:         defaultString('popuptext', 'Beregn hvad du kan spare ved at skifte din gamle benzin eller diesel bil ud, med denne lækre elbil. Det er hurtigt og nemt!'),
                    popup_button:       defaultString('popupbuttoncalc', 'Prøv nu!'),
                    popup_button_close: defaultString('popupbuttonclose', 'Luk'),
                    aside_field:        defaultString('f', undefined, {tooltip: true}),
                    aside_table:        defaultString('table', undefined, {tooltip: true}),
                    aside_ul:           defaultString('ul', undefined, {tooltip: true}),
                    aside_cta:          defaultString('cta', undefined, {tooltip: true}),
                    aside_video:        defaultString('video', undefined, {tooltip: true}),
                    aside_video_image:  defaultString('video_image', undefined, {tooltip: true}),
                    aside_image:        defaultString('img', undefined, {tooltip: true}),
                }, type, root);
                break;
            case 'findleasing':
                this.settings = new Widget(settings,{
                    header_label:       defaultString('l'),
                    css_class:          defaultString('class'),
                    order:              defaultString('order', 'left', defaultOrder),
                    size:               defaultString('s', '1/2', defaultSize),
                    toggle:             [Boolean, undefined, 'toggle',          false,          undefined,      defaultAnyData],
                    field_top:          defaultString('f', undefined, {tooltip: true}),
                    field_bottom:       defaultString('af', undefined, {tooltip: true}),
                    ul:                 defaultString('ul', undefined, {tooltip: true}),
                    table:              defaultString('table', undefined, {tooltip: true}),
                    cta:                defaultString('cta', undefined, {tooltip: true}),
                }, type, root);
                break;
            case 'carboost_exchange':
                this.settings = new Widget(settings,{
                    header_label:       defaultString('l'),
                    css_class:          defaultString('class'),
                    text:               defaultString('text', undefined, {tooltip: true}),
                    image:              defaultString('img', undefined, {tooltip: true}),
                    fh:                 defaultString('fh', undefined, {tooltip: true}),
                    returntext:         defaultString('return', undefined, {tooltip: true}),
                    backtext:           defaultString('back', undefined, {tooltip: true}),
                    submittext:         defaultString('submit', undefined, {tooltip: true}),
                    step1:              defaultString('step1', undefined, {tooltip: true}),
                    step2:              defaultString('step2', undefined, {tooltip: true}),
                    step3:              defaultString('step3', undefined, {tooltip: true}),
                    accept_label:       defaultString('accept_label', undefined, {tooltip: true}),
                    accept_link:        defaultString('accept_link', undefined, {tooltip: true}),
                }, type, root);
                break;
            case 'carboost_economy':
                this.settings = new Widget(settings,{
                    header_label:       defaultString('l'),
                    css_class:          defaultString('class'),
                    facts_text:         defaultString('facts', undefined, {tooltip: true}),
                }, type, root);
                break;
            case 'insurance':
                this.settings = new Widget(settings,{
                    css_class:          defaultString('class'),
                    header_label:       defaultString('l'),
                    h10:                defaultString('h10', undefined, {tooltip: true}),
                    h20:                defaultString('h20', undefined, {tooltip: true}),
                    h31:                defaultString('h31', undefined, {tooltip: true}),
                    h32:                defaultString('h32', undefined, {tooltip: true}),
                    monthly:            defaultString('monthly', undefined, {tooltip: true}),
                    thx:                defaultString('thx', undefined, {tooltip: true}),
                    thx_text:           defaultString('thx_text', undefined, {tooltip: true}),
                    cta_back:           defaultString('ctaback', undefined, {tooltip: true}),
                    cta1_txt:           defaultString('cta1_txt', undefined, {tooltip: true}),
                    cta3_txt:           defaultString('cta3_txt', undefined, {tooltip: true}),
                    logo:               defaultString('logo','logo', {tooltip: true}),
                }, type, root);
                break;
            case 'carShopInline':
                this.settings = new Widget(settings, {
                    header_label:   defaultString('l'),
                    css_class:      defaultString('class'),
                    fh:             defaultString('fh', 'Lej bilen her i få enkle steps'),
                    txt:            [TextObject, CarShopShareText, 'txt', undefined, undefined, defaultAnyData],
                }, type, root);
                break;
            case 'carShop':
                this.settings = new Widget(settings,{
                    header_label:       defaultString('l'),
                    css_class:          defaultString('class'),
                    fh:                 defaultString('fh', undefined, {tooltip: true}),
                    field:              defaultString('f', undefined, {tooltip: true}),
                    table:              defaultString('table', undefined, {tooltip: true}),
                    ul:                 defaultString('ul', undefined, {tooltip: true}),
                    field_2:            defaultString('f2', undefined, {tooltip: true}),
                    table_2:            defaultString('table2', undefined, {tooltip: true}),
                    ul_2:               defaultString('ul2', undefined, {tooltip: true}),
                    aside_table:        defaultString('atable', undefined, {tooltip: true}),
                    next:               defaultString('next', undefined, {tooltip: true}),
                    aside_ul:           defaultString('aul', undefined, {tooltip: true}),
                    aside_field:        defaultString('af', undefined, {tooltip: true}),
                    aside_cta:          defaultString('acta', undefined, {tooltip: true}),
                    video:              defaultString('video', undefined, {tooltip: true}),
                    image:              defaultString('img', undefined, {tooltip: true}),
                    video_image:        defaultString('video_image', undefined, {tooltip: true}),
                    txt:                [TextObject,    CarShopShareText,   'txt',          undefined,      undefined,      defaultAnyData],
                }, type, root);
                break;
            case 'carboostCalculation':
                this.settings = new Widget(settings,{
                    header_label:       defaultString('l'),
                    order:              defaultString('order', 'left', defaultOrder),
                    field:              defaultString('f', undefined, {tooltip: true}),
                    ul:                 defaultString('ul', undefined, {tooltip: true}),
                    table:              defaultString('table', undefined, {tooltip: true}),
                    cta:                defaultString('cta', undefined, {tooltip: true}),
                    field_2:            defaultString('f2', undefined, {tooltip: true}),
                    css_class:          defaultString('class'),
                    fh_txt:             defaultString('fh_txt', undefined, {tooltip: true}),
                    afh_txt:            defaultString('afh_txt', undefined, {tooltip: true}),
                    cta1_txt:           defaultString('cta1_txt', undefined, {tooltip: true}),
                    cta2_txt:           defaultString('cta2_txt', undefined, {tooltip: true}),
                    cta3_txt:           defaultString('cta3_txt', undefined, {tooltip: true}),
                    form_layouts:       [Object,        EasyDefaultSettingsListItems('form_layouts',    CalculationTemplatesItem), 'form_layouts',  undefined, undefined, defaultAnyData],
                    layouts:            [Object,        EasyDefaultSettingsListItems('layouts',         CalculationTemplatesItem), 'layouts',       undefined, undefined, defaultAnyData],
                    info_text:          [Object,        CustomJson,     'infotext', {},         undefined,      defaultAnyData],
                }, type, root);
                break;
            default:
                /**
                 * Default back to json object so we not validate data (it for component that is not defined yet)
                 * @type {CustomJson}
                 */
                this.settings = new CustomJson(settings);
                break;
        }
    }

    validate(){
        return this.type !== undefined;
    }

    output(){
        let settings = this?.settings?.output() ?? undefined;
        let rules = this.rules;
        let type = this.type;

        console.log('widget settings::' + type + ':: ', this?.settings?.output())

        if(type === undefined){
            return undefined;
        }
        else if(type === 'hr'){
            return {
                t: this.type,
                r: this.rules ?? undefined
            };
        }

        if(rules === ''){
            rules = undefined;
        }

        return {
            t: type,
            r: rules,
            s: settings
        };
    }
}
class banners extends listItemsDefault {
    constructor(params, root) {
        super(bannerItem, params, root);
    }
}

class bannerItem extends DefaultSettingsItem{
    constructor(params, root) {
        // before label and value was two values - not sure that is ok idé
        // so logic is merge - in this cases content is split into more element, so we
        // can not merge prefix and suffix and value into one. but it fine for now.

        if(root._version === 1 && (params.l?.toString()?.trim()?.length > 0 || params?.v?.startsWith('data.'))){
            let newValue = oldFunctionFormat((params.v ?? params?.l), params, (value) => {
                return `this.product.${value[1]}`;
            });

            params.v = newValue;
            params.l = undefined;
            params.fa = undefined;
            params.f = undefined;
            root.version = 2;
        }

        super(params,{
            value:          [String, undefined, 'v', undefined, setIfEmpty],
            position:       [String, undefined, 'p', undefined, setIfEmpty],
            rule:           [String, undefined, 'r', undefined, setIfEmpty],
            background:     [String, undefined, 'b', undefined, setIfEmpty],
            text_color:     [String, undefined, 'tc', undefined, setIfEmpty],
            prefix:         [String, undefined, 'lb', undefined, setIfEmpty],
            suffix:         [String, undefined, 'a', undefined, setIfEmpty],
            class:          [String, undefined, 'class', undefined, setIfEmpty],
            icon:           [String, undefined, 'i', undefined, setIfEmpty]
        });
    }

    validate(){
        return (this.value !== undefined && this.position !== undefined);
    }
}
class gridFields extends listItemsDefault {
    constructor(params, root) {
        super(gridFieldItem, params, root);
    }
}
class gridFieldItem extends DefaultSettingsItem{
    constructor(params, root) {
        // transform from old format to new format.
        // new format not using format, format args and after and before.
        if(root._version === 1 && (params?.a || params?.b || params?.f || params?.fa || params?.v?.startsWith('data.'))){
            console.log("root._version", root._version);
            let newValue = oldFunctionFormat(params?.v, params, (value) => {
                return `this.product.${value[1]}`;
            });
            console.info("params.a :", params.a  ?? undefined);
            console.info("params.b :", params.b  ?? undefined);
            console.info("params.f :", params.f  ?? undefined);
            console.info("params.fa:", params.fa ?? undefined);

            if(params?.b){
                newValue = params.b + ' ' + newValue;
            }
            if(params.a){
                newValue = newValue + ' ' + params.a;
            }

            params.v        = newValue;
            params.a        = undefined;
            params.b        = undefined;
            params.f        = undefined;
            params.fa       = undefined;
            root.version    = 2;
        }

        super(params, {
            label: [String, undefined, 'l', undefined],
            value: [String, undefined, 'v', undefined],
            icon:  [String, undefined, 'i', undefined, setIfEmpty],
            rule:  [String, undefined, 'r', undefined],
            show:  [Boolean, undefined, 's', true],
        });
    }

    validate(){
        /**
         * @TODO check later if this can make problem later.
         * change validate logic after see https://microlease.dk/leje-biler/citron/c1/10-4-drs-m-bagklap~93956
         * so i remove lavel from validates for now - maybe validate logic need to be more one rule like both label and value need in some cases
         * and value and other field in other case.
         * {
         *  "t": "string",
         *  "r": "$(data.info.doors)",
         *  "v": "data.info.doors",
         *  "i": "cc-icon-car",
         *  "a": "døre"
         * },
         */
        return this.value !== undefined;
    }
}

class splitPartItem extends DefaultSettingsItem{
    constructor(params) {
        super(params, {
            label: [String, undefined, 'l', undefined],
            widget: [String, undefined, 'w', undefined],
            rule: [String, undefined, 'r', undefined],
        });
    }

    validate(){
        return this.label !== undefined && this.widget !== undefined;
    }
}
class splitParts extends listItemsDefault {
    constructor(params) {
        super(splitPartItem, params);
    }
}

class CarOptionsIntroLayout extends DefaultSettingsItem{
    constructor(params) {
        if(params?.mode === 'split'){
            params.mode = true;
        }

        super(params, {
            mode: [Boolean, undefined, 'mode', false],
            parts: [Object, splitParts, 'parts', undefined],
        });
    }

    validate(){
        return this.mode === true;
    }

    output() {
        let c = super.output();

        if(c?.mode === true && c?.parts?.length > 0){
            c.mode = 'split';
            return c;
        }

        return undefined;
    }
}

class CarOptionsIntroVideo extends DefaultSettingsItem{
    constructor(params) {
        super(params, {
            hide: [Boolean, undefined, 'hide', false],
            btn: [Boolean, undefined, 'btn', true],
            btn_p: [String, undefined, 'btn_p', '3', setIfEmpty],
            btn_l: [String, undefined, 'btn_l', 'Se video', setIfEmpty],
        });
    }
}

class CarOptionsIntroSpec extends DefaultSettingsItem{
    constructor(params) {
        super(params, {
            mileage: [String, undefined, 'mileage', 'Kilometer', setIfEmpty],
            year: [String, undefined, 'year', 'Modelår', setIfEmpty],
            propellant: [String, undefined, 'propellant', 'Drivmiddel', setIfEmpty],
            range: [String, undefined, 'range', 'Rækkevidde', setIfEmpty],
            gear: [String, undefined, 'gear', 'Gearkasse', setIfEmpty],
            hp: [String, undefined, 'hp', 'Hestekræfter', setIfEmpty],
        });
    }
}

class CustomSpecsItemFieldItem extends DefaultSettingsItem {
    constructor(params, root) {
        console.log('root', root);
        if(root._version === 1 && params.v !== undefined){
            let newValue = oldFunctionFormat('data.' + params?.v, params, (value) => {
                if((params?.s ?? true) === true){
                    return `this?.product?.${value[1]} ?? '-'`;
                }
                else{
                    return `this.product.${value[1]}`;
                }
            });

            if((params?.s ?? true) === false){
                params.r = params?.r ? params.r : `$(${params?.v})`;
            }

            params.o = newValue;
            params.v = undefined;
            params.f = undefined;
            params.s = undefined;
        }

        super(params, {
            label: [String, undefined, 'l', undefined],
            icon: [String, undefined, 'i', undefined],
            after: [String, undefined, 'a', undefined],
            content:  [String, undefined, 'o', undefined],
            before: [String, undefined, 'b', undefined],
            rules: [String, undefined, 'r', undefined],
        }, 'custom_specs_field_items', false, root);
    }

    validate(){
        return this.label !== undefined && this.content !== undefined;
    }
}

class IntroGroupFieldsItem extends DefaultSettingsItem {
    constructor(params, root) {
        if(root._version === 1 && params.v !== undefined){
            let newValue = oldFunctionFormat('data.' + params?.v, params, (value) => {
                if((params?.s ?? true) === true){
                    return `this?.product?.${value[1]} ?? '-'`;
                }
                else{
                    return `this.product.${value[1]}`;
                }
            });

            // $(engine.propellant) !== 'EL'
            if((params?.s ?? true) === false){
                params.r = params?.r ? params.r : `$(${params?.v})`;
            }

            params.o = newValue;
            params.v = undefined;
            params.f = undefined;
            params.s = undefined;
        }

        super(params, {
            label:    [String, undefined, 'l', undefined],
            after:    [String, undefined, 'a', undefined],
            before:    [String, undefined, 'b', undefined],
            content:  [String, undefined, 'o', undefined],
            rules:    [String, undefined, 'r', undefined],
        }, 'intro_group_fields_item', false, root);
    }

    validate(){
        return this.label !== undefined && this.content !== undefined;
    }
}

class CustomSpecsItemFields extends listItemsDefault{
    constructor(params, root) {
        super(CustomSpecsItemFieldItem, params, root);
    }
}

class IntroGroupFields extends listItemsDefault{
    constructor(params, root) {
        console.log("IntroGroupFields", root)
        super(IntroGroupFieldsItem, params, root);
    }
}

class customSpecItem extends DefaultSettingsItem {
    constructor(params, root) {
        console.log('customSpecItem', params);
        super(params, {
            fields: [Object, CustomSpecsItemFields, 'f', undefined],
            rule: [String, undefined, 'r', undefined],
        }, 'custom_spec_item', false, root);
    }

    validate(){
        return this.fields !== undefined;
    }
}

class IntroGroup extends DefaultSettingsItem{
    constructor(params, root) {
        console.log("IntroGroup", root)
        super(params,  {
            label: [String,  undefined, 'l', undefined, setIfEmpty],
            fields: [Object,  IntroGroupFields, 'f', undefined, setIfEmpty],
            rule:  [String,  undefined, 'r', undefined],
        }, 'IntroGroup', false, root);
    }

    validate(){
        return this.label !== undefined && this.fields !== undefined;
    }
}

class customSpecs extends listItemsDefault{
    constructor(params,root) {
        super(customSpecItem, params, root);
    }
}
class IntroGroups extends listItemsDefault {
    constructor(params, root) {
        console.log('IntroGroups',root)
        super(IntroGroup, params, root);
    }
}

class CarOptionsIntro extends DefaultSettingsItem{
    constructor(params, root) {
        if(params?.video === undefined || typeof params.video === 'boolean'){
            params.video = {};
        }

        super(params, {
            video: [Object, CarOptionsIntroVideo, 'video', undefined],
            spec: [Object, CarOptionsIntroSpec, 'spec', undefined],
            layout: [Object, CarOptionsIntroLayout, 'layout', undefined],
            custom_spec: [Object, customSpecs, 'customspec', undefined],
            vehicle_type: [Boolean, undefined, 'vehicletype', true],
            hide_spec: [Boolean, undefined, 'hidespec', false],
            disable_price: [Boolean, undefined, 'disablePrice', false],
            tax: [Boolean, undefined, 'tax', undefined],
            text_below_price: [String, undefined, 'f', undefined], // @test need to be tested
            gal_disable_zoom: [Boolean, undefined, 'disableZoom', false],
            gal_fulls_screen: [Boolean, undefined, 'fullScreen', false],
            gal_thumbs_position: [String, undefined, 'thumbsPosition', 'right', setIfEmpty],
        }, 'car_options_intro', false, root);
    }

    output() {
        const content = super.output();

        if(content === undefined){
            return undefined;
        }

        return {
            ...content,
            spec: content?.hide_spec === true ? undefined : content?.spec,
        }
    }
}

class carOptionsCta extends listItemsDefault {
    constructor(params, root) {
        super(carOptionsCtaItem, params, root);
    }


    static v1Tov2(s){
        function helpV1ToV2(key, e){
            if(s?.[key] === undefined){
                return undefined;
            }


            return e(s[key]);
        }

        return [
            helpV1ToV2('contact', (s) => {
                return {
                    t: 'form',
                    l: (s?.l ?? 'contact'),
                    m: (s?.m ?? false),
                    r: 'true',
                    k: 'contact',
                    u: s?.link ?? undefined,
                }
            }),
            helpV1ToV2('exchange', (s) => {
                return {
                    t: 'form',
                    l: (s?.l ?? 'exchange'),
                    m: (s?.m ?? false),
                    r: 'true',
                    k: 'exchange',
                    u: s?.link ?? undefined,
                }
            }),
            helpV1ToV2('autoproff', (s) => {
                return {
                    t: 'autoproff',
                    l: (s?.l ?? 'autoproff'),
                    m: (s?.m ?? false),
                    r: 'true',
                    u: s?.link ?? undefined,
                }
            }),
            helpV1ToV2('autoitexchange', (s) => {
                return {
                    t: 'none-trigger',
                    l: (s?.l ?? 'autoitexchange'),
                    m: (s?.m ?? false),
                    r: 'true',
                    u: s?.link ?? undefined,
                    a: {
                        "data-btcontentid" : (s?.id ?? undefined),
                        "data-btsettings-settingsguid" : (s?.guid ?? undefined),
                        "data-btsettings-color" : (s?.bg ?? undefined),
                        "data-btsettings-fontcolor" : (s?.c ?? undefined),
                    },
                }
            }),
            helpV1ToV2('solgt_com', (s) => {
                return {
                    t: 'click-element',
                    l: (s?.l ?? 'solgt_com'),
                    m: (s?.m ?? false),
                    r: 'true',
                    u: s?.link ?? undefined,
                    k: '#widget-shortpop-button',
                }
            }),
            helpV1ToV2('solgt_com_2', (s) => {
                return {
                    t: 'click-element',
                    l: (s?.l ?? 'solgt_com_2'),
                    m: (s?.m ?? false),
                    r: 'true',
                    u: s?.link ?? undefined,
                    k: '#solgt-webkit-button',
                }
            }),
            helpV1ToV2('autouncle_trade_in', (s) => {
                return {
                    t: 'auws',
                    l: (s?.l ?? 'autouncle_trade_in'),
                    m: (s?.m ?? false),
                    r: 'true',
                    u: s?.link ?? undefined,
                    k: 'TRADE_IN',
                }
            }),
            helpV1ToV2('testdrive2', (s) => {
                return {
                    t: 'form',
                    l: (s?.l ?? 'testdrive2'),
                    m: (s?.m ?? false),
                    r: 'true',
                    u: s?.link ?? undefined,
                    k: 'testdrive2',
                }
            }),
            helpV1ToV2('testdrive', (s) => {
                return {
                    t: 'form',
                    l: (s?.l ?? 'testdrive'),
                    m: (s?.m ?? false),
                    r: 'true',
                    u: s?.link ?? undefined,
                    k: 'testdrive',
                }
            }),
            helpV1ToV2('autouncle_test_drive', (s) => {
                return {
                    t: 'auws',
                    l: (s?.l ?? 'autouncle_test_drive'),
                    m: (s?.m ?? false),
                    r: 'true',
                    u: s?.link ?? undefined,
                    k: 'IMPROVED_BOOK_TEST_DRIVE',
                }
            }),
            helpV1ToV2('autouncle_price_alert', (s) => {
                return {
                    t: 'auws',
                    l: (s?.l ?? 'autouncle_price_alert'),
                    m: (s?.m ?? false),
                    r: 'true',
                    u: s?.link ?? undefined,
                    k: 'PRICE_ALERT',
                }
            }),
            helpV1ToV2('call', (s) => {
                return {
                    t: 'phone',
                    l: (s?.l ?? 'call'),
                    m: (s?.m ?? false),
                    r: 'true',
                    u: s?.link ?? undefined,
                    k: 'phone',
                    p: s?.phone ?? undefined,
                }
            }),
            helpV1ToV2('sms', (s) => {
                return {
                    t: 'phone',
                    l: (s?.l ?? 'sms'),
                    m: (s?.m ?? false),
                    r: 'true',
                    u: s?.link ?? undefined,
                    k: 'sms',
                    p: s?.phone ?? undefined,
                }
            })
        ].filter((item) => item)
    }
}

class carOptionsCtaItemAttrs extends BasicSimpleItemsKeyValue{}

class carOptionsCtaItem extends DefaultSettingsItem{
    constructor(params, root)
    {
        const {setString, setBoolean, setSymbioteItem, setClass, setObject} = carOptionsCtaItem.setupFunction({});

        super(params, {
            type: setString('t', {}, {type:'select', options:{
                'form':'form','autoproff':'autoproff', 'none-trigger':'none-trigger',
                'click-element':'click-element', 'auws':'auws', 'phone':'phone', 'scroll_to_element':'scroll_to_element'}
            }),
            mobile: setBoolean('m'),
            label: setString('l'),
            rule: setString('r'),
            link: setString('u'),
            custom_logic: setString('c'),
            eventName: setString('e', 'cta-form'),
            attr: setObject('a', {classType:carOptionsCtaItemAttrs}),
            settings: setSymbioteItem('settings', carOptionsCtaItem._setCtaOptions(params?.t ?? undefined))
        }, 'car_options_cta_item', true, root);
    }

    isMainFields(){
        return Object.values(this._props).filter((item) => item !== 'settings')
    }

    static _setCtaOptions(type){
        const {setString} = carOptionsCtaItem.setupFunction()
        switch(type){
            case 'click-element':
                return {
                    element: setString('k')
                }
            case 'auws':
                // k can be one of 3 values:
                // IMPROVED_BOOK_TEST_DRIVE | PRICE_ALERT | TRADE_IN
                return {
                    mode : setString('k', {}, {type:'select', options:['IMPROVED_BOOK_TEST_DRIVE', 'PRICE_ALERT', 'TRADE_IN']}),
                };
            case 'form':
                return {
                    formName: setString('k', {}, {type:'select', options:['contact', 'exchange', 'testdrive', 'testdrive2']}),
                }

            case 'phone':
                return {
                    store_type : setString('k', {},{type:'select', options:['phone', 'sms']}),
                    phone : setString('p'),
                }

            case 'scroll_to_element':
                return {
                    element: setString('k'),
                }
            default:
            case 'none-trigger':
                return {
                }
        }
    }

    changeType(type){
        this.settings.updateLayoutInstance(
            carOptionsCtaItem._setCtaOptions(type)
        )
    }

    validate(){
        return this.label !== undefined && this.type !== undefined;
    }
}

// Work here
class carOptionsCtaOld extends DefaultSettingsItem{
    constructor(params) {
        const defaultLayout = {
            label: [String, undefined, 'l', undefined, setIfEmpty],
            mobile: [Boolean, undefined, 'm', false],
        }

        function defaultOutputFilter(value){
            // if only value is mobile then return undefined
            if(value?.l === undefined && value?.m === true){
                return undefined;
            }

            return value;
        }

        super(params, {
            contact:                [Object, EasyDefaultSettingsItem('car_options_cta_contact', defaultLayout, defaultOutputFilter), 'contact', undefined],
            exchange:               [Object, EasyDefaultSettingsItem('car_options_cta_exchange',defaultLayout, defaultOutputFilter), 'exchange', undefined],
            auto_proff:             [Object, EasyDefaultSettingsItem('car_options_cta_auto_proff', defaultLayout, defaultOutputFilter), 'autoproff', undefined],
            autoit_exchange:        [Object, EasyDefaultSettingsItem('car_options_cta_autoit_exchange', {
                ...defaultLayout,
                id:                 [String, undefined, 'id', undefined, setIfEmpty],
                guid:               [String, undefined, 'guid', undefined, setIfEmpty],
                bg:                 [String, undefined, 'bg', undefined, setIfEmpty],
                color:              [String, undefined, 'c', undefined, setIfEmpty],
            }, defaultOutputFilter), 'autoitexchange', undefined],
            solgt_com:              [Object, EasyDefaultSettingsItem('car_options_cta_solgt_com', defaultLayout, defaultOutputFilter), 'solgt_com', undefined],
            auto_uncle_trade_in:    [Object, EasyDefaultSettingsItem('car_options_cta_auto_uncle_trade_in', defaultLayout, defaultOutputFilter), 'autouncle_trade_in', undefined],
            testdrive2:             [Object, EasyDefaultSettingsItem('car_options_cta_testdrive2', defaultLayout,defaultOutputFilter), 'testdrive2', undefined],
            testdrive:              [Object, EasyDefaultSettingsItem('car_options_cta_testdrive', defaultLayout,defaultOutputFilter), 'testdrive', undefined],
            auto_uncle_test_drive:  [Object, EasyDefaultSettingsItem('car_options_cta_auto_uncle_test_drive', defaultLayout, defaultOutputFilter), 'autouncle_test_drive', undefined],
            auto_uncle_price_alert: [Object, EasyDefaultSettingsItem('car_options_cta_auto_uncle_price_alert', defaultLayout, defaultOutputFilter), 'autouncle_price_alert', undefined],
            call:                   [Object, EasyDefaultSettingsItem('car_options_cta_call', {
                ...defaultLayout,
                phone:              [String, undefined, 'phone', undefined, setIfEmpty],
            }, defaultOutputFilter), 'call', undefined],
            sms:                    [Object, EasyDefaultSettingsItem('car_options_cta_sms', {
                ...defaultLayout,
                phone:              [String, undefined, 'phone', undefined, setIfEmpty],
            }, defaultOutputFilter), 'sms', undefined],
        }, 'car_options_cta', false);
    }
    output() {
        let output = super.output();
        return output;
    }

}
class carOptions extends DefaultSettingsItem{
    constructor(params, root) {


        if(typeof params.cta && !Array.isArray(params.cta) && root._version === 1){
            params.cta = carOptionsCta.v1Tov2(params.cta);
        }

        super(params, {
            cta: [Array, carOptionsCta, 'cta', undefined],
            intro: [Object, CarOptionsIntro, 'intro', undefined],
            parts: [Array, CarParts, 'parts', undefined],
            book_bill: [Boolean, undefined, 'bookbill', false],
            videos: [Mixed, undefined, 'videos', undefined],
            disable_price: [Boolean, undefined, 'disablePrice', false],
        }, undefined, false, root);
    }

    output(){
        let c = super.output();

        return c;

    }
}
class financing extends DefaultSettingsItem{
    constructor(params) {
        super(params, {
            label: [String, undefined, 'l', undefined],
            after: [String, undefined, 'a', undefined],
            tooltip: [Boolean, undefined, 'tt', false],
            car: [Boolean, undefined, 'car', false],
        });
    }
}

class strokeVolume extends DefaultSettingsItem{
    constructor(params) {
        super(params, {
            show: [Boolean, undefined, 's', false],
            el: [String, undefined, 'el', undefined], // code here is Boolean but not sure real use yet
        });
    }
}
class Category extends listItemsObject {
    constructor(params) {
        super(categoryItem, params);
    }
}

class Equipments extends listItemsObject {
    constructor(params) {
        super(equipmentsItem, params);
    }
}

class extraPrice extends listItemsObject {
    constructor(params, root) {
        super(extraPriceItem, params, root);
    }

    toggle(){
        this._hidden = !this._hidden;
    }
}
class extraPriceItem extends DefaultSettingsItem {
    constructor(params) {
        console.log('extraPriceItem', params)
        super(params, {
            _key: [String, undefined, '_key', undefined],
            label: [String, undefined, 'label', undefined],
            price: [Number, undefined, 'price', undefined],
        });
    }

    validate(){
        return this.price !== undefined && this.label !== undefined && this._key !== undefined;
    }
}
class Ads extends DefaultSettingsItem{
    constructor(params) {
        const {setArray, setBoolean, setInt} = Ads.setupFunction()
        super(params, {
            cleanup: setBoolean('cleanup', false),
            randomize: setBoolean('randomize', false),
            funky_randomize: setBoolean('funky_randomize', false),
            items: setArray('items', AdsItems),
            offer_30: setInt('o30', 10),
            offer_60: setInt('o64', 12),
        });
    }

    output() {
        if(this.items.length === 0){
            return undefined;
        }

        return super.output();
    }
}
class AdsItems extends listItemsDefault {
    constructor(params) {
        super(AdsItem, params);
    }
}
class AdsItem extends DefaultSettingsItem{
    constructor(params) {
        super(params, {
            label: [String, undefined, 'l', undefined, setIfEmpty],
            position: [String, undefined, 'p', undefined, setIfEmpty],
            background: [String, undefined, 'b', undefined, setIfEmpty],
            template: [String, undefined, 't', undefined, setIfEmpty],
            rule: [String, undefined, 'r', undefined, setIfEmpty],
            content: [String, undefined, 'c', undefined, setIfEmpty],
            url: [String, undefined, 'u', undefined, setIfEmpty],
        });
    }

    validate(){
        return (
            this.label !== undefined
        );
    }
}
class equipmentsItem{
    /**
     * @type {string|undefined}
     * required
     */
    label = undefined;
    /**
     * @type {string|undefined}
     * required
     */
    _key = undefined;

    constructor(params) {
        if(params?._value !== undefined){
            this.label = params?._value;
        }
        else {
            this.label = params?.label ?? undefined;
        }
        this._key = params?._key ?? undefined;
    }

    output(){
        return this.label !== undefined ? this.label : undefined;
    }

    validate(){
        return this.label !== undefined && this._key !== undefined;
    }
}
class categoryItem{
    /**
     * @type {string|undefined}
     * required
     */
    _key = undefined;
    /**
     * @type {string|undefined}
     * required
     */
    label = undefined;
    /**
     * @type {string|undefined}
     */
    image = undefined;
    /**
     * @type {string|undefined}
     */
    active_image = undefined;

    constructor(params) {
        if(params?._value !== undefined){
            this.label = params?._value;
        }
        else {
            this.label = params?.label ?? undefined;
        }
        this._key = params?._key ?? undefined;
        this.image = params?.image ?? undefined;
        this.active_image = params?.active_image ?? undefined;
    }

    output(){
        // if image and active_image are not defined, return only label as string
        if(this.label !== undefined && this._key !== undefined && this.image === undefined && this.active_image === undefined){
            return this.label;
        }

        return {
            label: this.label,
            image: this.image?.trim()?.length > 0 ? this.image : undefined,
            active_image: this?.active_image?.trim().length > 0 ? this.active_image : undefined
        };
    }

    validate(){
        return this.label !== undefined && this._key !== undefined;
    }
}

/**
 * @property {boolean} r
 * @property {boolean} show
 * @property {string} v
 * @property {string} custom_label
 * @property {string} custom_rule
 */
class PriceLabel extends DefaultSettingsItem{
    constructor(params) {
        super(params, {
            show: [Boolean, undefined, 's', false],
            type: [Boolean, undefined, 'v', false],
            /** Som jeg forstå på min god ven ulrik,
             *  kan dette her sikker fjernes og lave dynamic - sprøg susanne om hvor hun bruger det.
             * */
            r: [String, undefined, 'r', undefined, setIfEmpty],
            /**
             * Need to be change to more dynamic way
             * - https://car-ads-workspace.slack.com/archives/D022YDEJB98/p1714997478392999
             */
            custom_label: [String, undefined, 'custom_label', undefined, setIfEmpty],
            custom_label_two: [String, undefined, 'custom_label_two', undefined, setIfEmpty],
            custom_label_three: [String, undefined, 'custom_label_three', undefined, setIfEmpty],
            custom_rule: [String, undefined, 'custom_rule', undefined, setIfEmpty],
            custom_rule_two: [String, undefined, 'custom_rule_two', undefined, setIfEmpty],
            custom_rule_three: [String, undefined, 'custom_rule_three', undefined, setIfEmpty],
        });
    }
    validate(){
        super.output();
    }
}

/**
 * @property {boolean} show
 * @property {string} label
 */
class LeasingToolTip extends DefaultSettingsItem{
    constructor(params) {
        super(params, {
            label : [String, undefined, 'l', undefined, setIfEmpty],
            show : [Boolean, undefined, 's', false]
        });
    }
}

class LeasingInline extends DefaultSettingsItem{
    constructor(params) {
        super(params, {
            label : [String, undefined, 'l', undefined, setIfEmpty],
            show : [Boolean, undefined, 's', false],
            down_payment: [String, undefined, 'downpayment', undefined, setIfEmpty],
            duration: [String, undefined, 'duration', undefined, setIfEmpty],
            mdr: [String, undefined, 'mdr', undefined, setIfEmpty],
            km: [String, undefined, 'km', undefined, setIfEmpty],
            total_payment: [String, undefined, 'totalpayment', undefined, setIfEmpty],
        });
    }
}

class MiniLeaseInfo extends DefaultSettingsItem{
    constructor(params) {
        super(params, {
            label : [String, undefined, 'l', undefined, setIfEmpty],
            show : [Boolean, undefined, 's', false],
            upfront: [String, undefined, 'upfront', undefined, setIfEmpty],
            period_label: [String, undefined, 'period_label', undefined, setIfEmpty],
            period: [String, undefined, 'period', undefined, setIfEmpty],
            km_label: [String, undefined, 'km', undefined, setIfEmpty],
            monthly_label: [String, undefined, 'monthly', undefined, setIfEmpty],
        });
    }
}

/**
 * @property {string} label
 * @property {string} after
 * @property {boolean} tooltip
 * @property {boolean} car
 * @property {boolean} main
 * @property {boolean} car_link
 */
class Santander extends DefaultSettingsItem{
    constructor(params) {
        super(params, {
            label:      [String,    undefined,      'l',        undefined, setIfEmpty],
            after:      [String,    undefined,      'a',        undefined, setIfEmpty],
            tooltip:    [Boolean,   undefined,      'tt',       false],
            car:        [Boolean,   undefined,      'car',      false],
            main:       [Boolean,   undefined,      'main',     false],
            car_link:   [Boolean,   undefined,      'carlink',  true],
        });
    }
}

/**
 * @property {string} source
 * @property {string} layout
 * @property {string} theme
 */
class AutoUncle extends DefaultSettingsItem{
    constructor(params) {
        super(params, {
            source:     [String,    undefined,  'source',   undefined,  setIfEmpty],
            layout:     [String,    undefined,  'layout',   undefined,  setIfEmpty],
            theme:      [String,    undefined,  'theme',    undefined,  setIfEmpty],
        });
    }
}

/**
 * @property {string} label
 * @property {string} b
 * @property {string} i
 */
class AutoTjek extends DefaultSettingsItem{
    constructor(params) {
        super(params, {
            label:          [String,    undefined,  'l',    undefined,  setIfEmpty],
            background:     [String,    undefined,  'b',    undefined,  setIfEmpty],
            image:          [Int,       undefined,  'i',    undefined,  setIfEmpty],
        });
    }
}

class Breakpoints extends DefaultSettingsItem{
    constructor(params) {
        super(params, {
            small:          [Int,       undefined,  's',    1,  setIfEmpty],
            medium:         [Int,       undefined,  'm',    2,  setIfEmpty],
            large:          [Int,       undefined,  'l',    3,  setIfEmpty],
        }, 'Breakpoints', false);
    }
}

class TextSearchOptions extends BasicSimpleItemsKeyValue{}

/**
 * @param {DefaultSettingsComponent} ob
 */
function osMappingProducts(ob){
    const {setArray, setClass, setString, setBoolean, setInt} = ob.constructor.setupFunction();

    return {
        search:                     setClass('search', Search),
        price_priorities:           setArray('pricePriorities', pricePriorities),
        price_second_priorities:    setArray('priceSecondPriorities', pricePriorities),
        price_label:                setClass('pricelabel', PriceLabel),
        banner:                     setClass('banner', banners),
        grid_fields:                setArray('gridFields', gridFields),
        slag_volume:                setClass('slagVolume', strokeVolume),
        financing:                  setClass('financing', financing),
        extra_price:                setClass('extraPrice', extraPrice),
        no_cache:                   setBoolean('noCache', true),
        sort:                       setString('sort', 'created:desc'),
        internal:                   setBoolean('internal', false),
        leasing_inline:             setClass('leasing_inline', LeasingInline),
        mini_lease_info:            setClass('miniLease_info', MiniLeaseInfo),
        sold_link:                  setBoolean('soldLink',false),
        auto_tjek:                  setClass('autoTjek', AutoTjek),
        overwrite_url:              setString('overwrite_url'),
        leasing_tooltip:            setClass('leasingTooltip', LeasingToolTip),
        santander:                  setClass('santander', Santander),
        auto_uncle:                 setClass('autoUncle', AutoUncle),
        price_second_layout:        setInt('price_second_layout', 1),
    };
}
/**
 * @param {DefaultSettingsComponent} ob
 */
function osMappingSearch(ob){
    const {setArray, setClass, setString, setBoolean, setInt} = ob.constructor.setupFunction();

    return {
        filter:                 setArray('filter', filters),
        top_page:               setClass('top_page', Page),
        bottom_page:            setClass('bottom_page', Page),
        car:                    setClass('car', carOptions), // [Object, carOptions, {}, undefined],
        ads:                    setClass('ads', Ads),
        page_key:               setString('pageKey'),
        query:                  setClass('query', CustomJson),
        categories:             setClass('categories', Category),
        equipments:             setClass('equipments', Equipments),
        debug:                  setBoolean('debug', false),
        title_tag:              setString('title_tag', 'h2'),
        breadcrumb:             setBoolean('breadcrumb', false),
        equipments_name:        setString('equipments_name'),
        categories_name:        setString('categories_name'),
        text_search_options:    setClass('text_search_options', TextSearchOptions, {}),
        back_in_url:            setBoolean('back_in_url', false),
        robots:                 setString('robots', 'follow, index'),
        robots_car:             setString('robots_car', 'noindex, nofollow'),
        title:                  setString('title'),
        page_info:              setBoolean('pageInfo', false),
        search_info:            setBoolean('search_info', false),
        cid :                   setInt('cid'),
        fid :                   setInt('fid'),
        description:            setString('description'),
        saerch_bar_sticky:      setBoolean('saerch_bar_sticky', false),
        disable_search_bar:     setBoolean('disableSearchBar', false),
        disable_filter:         setBoolean('disableFilter', false),
        filter_toggle:          setBoolean('filterToggle', false),
        list_layout:            setInt('list_layout', 1),
        car_link_tag:           setString('tag', 'router-link'), // not normally something you need to change but can be change to link or router-link
    }
}

/**
 * Feed
 * -----------------------------------------------------------------
 * @version  1
 * @property {boolean} page_info
 * @property {number} cid
 * @property {number} fid
 * @property {string} title
 * @property {string} robots
 * @property {string} sort
 * @property {boolean} back_in_url
 * @property {boolean} no_cache
 * @property {Page} top_page
 * @property {Page} bottom_page
 * @property {pricePriorities} price_priorities
 * @property {pricePriorities} price_second_priorities
 * @property {string} categories_name
 * @property {string} equipments_name
 * @property {Category} categories
 * @property {Equipments} equipments
 * @property {CustomJson} query
 * @property {string} page_key
 * @property {CustomJson} text_search_options
 * @property {Search} search
 * @property {filters} filter
 * @property {carOptions} car
 * @property {PriceLabel} price_label
 * @property {banners} banner
 * @property {gridFields} grid_fields
 * @property {boolean} internal
 * @property {boolean} breadcrumb
 * @property {string} title_tag
 * @property {strokeVolume} slag_volume
 * @property {financing} financing
 * @property {extraPrice} extra_price
 * @property {Ads} ads
 * @property {string} overwrite_url
 * @property {LeasingToolTip} leasing_tooltip
 * @property {LeasingInline} leasing_inline
 * @property {MiniLeaseInfo} mini_lease_info
 * @property {Santander} santander
 * @property {AutoUncle} auto_uncle
 * @property {number} price_second_layout
 * @property {boolean} sold_link
 * @property {AutoTjek} auto_tjek
 * @property {string} description
 */
export class Feed extends DefaultSettingsComponent{
    version = 1;
    _version = 1;

    constructor(ob){
        super();
        this.version = ob?.version ?? 1;
        this._version = ob?.version ?? 1;

        if(ob.title_tag?.startsWith("H") === true){
            ob.title_tag = ob.title_tag.toLowerCase();
        }

        if(ob.robots !== undefined){
            ob.robots = ob.robots.toLowerCase();
        }

        if(ob.robots_car !== undefined){
            ob.robots_car = ob.robots_car.toLowerCase();
        }

        this.init(ob, {
            ...osMappingSearch(this),
            ...osMappingProducts(this)
        }, 'feed', false, this)
    }


    output(){
        const output = super.output();
        return {
            version: (this.version),
            editor: 'virtual-config',
            cid: this.cid ? Int(this.cid) : undefined,
            fid: this.fid ? Int(this.fid) : undefined,
            ...output,
            price_second_layout: (
                this?.price_second_layout === 0 || this?.price_second_layout === 2 ? Int(this?.price_second_layout) : undefined
            )
        };
    }

    getName(){
        return 'product_search';
    }
}
/**
 * Slider
 * ---------------------------------------------------
 * @version 1
 * @property {pricePriorities} price_priorities
 * @property {pricePriorities} price_second_priorities
 * @property {PriceLabel} price_label
 * @property {banners} banner
 * @property {gridFields} grid_fields
 * @property {strokeVolume} slag_volume
 * @property {financing} financing
 * @property {extraPrice} extra_price
 * @property {boolean} no_cache
 * @property {string} sort
 * @property {boolean} internal
 * @property {LeasingInline} leasing_inline
 * @property {MiniLeaseInfo} mini_lease_info
 * @property {boolean} sold_link
 * @property {AutoTjek} auto_tjek
 * @property {string} overwrite_url
 * @property {LeasingToolTip} leasing_tooltip
 * @property {Santander} santander
 * @property {AutoUncle} auto_uncle
 * @property {number} price_second_layout
 */
export class Slider extends DefaultSettingsComponent{
    version = 1;
    _version = 1;
    _id = null;

    constructor(ob){
        super();
        this.version = ob?.version ?? 1;
        this._version = ob?.version ?? 1;
        this._id = ob?.id ?? null;

        const {setArray, setClass, setString, setBoolean, setInt} = Slider.setupFunction();

        if(ob.hasOwnProperty('breakpoints') && Array.isArray(ob.breakpoints)) {
            ob.breakpoints = {
                s: ob.breakpoints?.[0] ?? undefined,
                m: ob.breakpoints?.[1] ?? undefined,
                l: ob.breakpoints?.[2] ?? undefined,
            }
        }

        this.init(ob, {
            fid: setInt('fid'),
            cid: setInt('cid'),
            breakpoints: setClass('breakpoints', Breakpoints),
            limit: setInt('limit', 10),
            button: setString('btn'),
            url: setString('url'),
            filter: setClass('filter', CustomJsonString),
            ...osMappingProducts(this),
        }, 'slider', false, this)
    }

    output(){
        const output = super.output();

        return {
            version: (this.version),
            editor: 'virtual-config',
            cid: this.cid ? Int(this.cid) : undefined,
            fid: this.fid ? Int(this.fid) : undefined,
            ...output,
        };
    }
    getName(){
        return 'slider_update_' + this._id;
    }
}

/**
 * @property {filters} filter
 * @property {pricePriorities} price_priorities
 * @property {pricePriorities} price_second_priorities
 * @property {PriceLabel} price_label
 * @property {banners} banner
 * @property {gridFields} grid_fields
 * @property {strokeVolume} slag_volume
 * @property {financing} financing
 * @property {extraPrice} extra_price
 * @property {boolean} no_cache
 * @property {string} sort
 * @property {boolean} internal
 * @property {LeasingInline} leasing_inline
 * @property {MiniLeaseInfo} mini_lease_info
 * @property {boolean} sold_link
 * @property {AutoTjek} auto_tjek
 * @property {string} overwrite_url
 * @property {LeasingToolTip} leasing_tooltip
 * @property {Santander} santander
 * @property {AutoUncle} auto_uncle
 * @property {number} price_second_layout
 */
export class QuickSearch extends DefaultSettingsComponent{
    version = 1;
    _version = 1;

    constructor(ob){
        super();
        this.version = ob?.version ?? 1;
        this._version = ob?.version ?? 1;


        const {setArray, setClass, setString, setBoolean, setInt} = QuickSearch.setupFunction();

        this.init(ob, {
            fid: setInt('fid'),
            cid: setInt('cid'),
            text: setString('text'),
            url: setString('url'),
            button: setString('btn'),
            layout: setString('layout', 'row'),
            use_search: setBoolean('use_search', false),
            slider: setBoolean('slider', false),
            filter: setArray('filter', filters),
            ...osMappingProducts(this),
        }, 'quick_search', false, this)
    }

    output(){
        const output = super.output();

        const defaultOutput = {
            version: (this.version),
            editor: 'virtual-config',
            cid: output.cid ? Int(output.cid) : undefined,
            fid: output.fid ? Int(output.fid) : undefined,
            text: output?.text ?? undefined,
            url: output.url ?? undefined,
            btn: output?.btn ?? undefined,
            layout: output.layout ?? undefined,
        };


        if(this.use_search === true && this.slider === false){
            return {
                use_search: output.use_search,
                filter: output?.filter ?? [],
                ...defaultOutput,
            };
        }
        else if(this.use_search === true && this.slider === true){
            return {
                ...defaultOutput,
                ...output,
            };
        }

        return {
            ...defaultOutput
        };
    }
    getName(){
        return 'quick_search_update'
    }
}


function openCorrectConfig(params, config){
    if(params?.config === 'app'){
        return new Feed(JSON.parse(config));
    }
    else if(params?.config === 'quick_search'){
        return new QuickSearch(JSON.parse(config));
    }
    else if(params?.config === 'slider'){
        return new Slider(JSON.parse(config));
    }

    return new Feed(JSON.parse(config));
}

/**
 * @returns {{currentFeed: Ref<Feed>}}
 */
export function useVirtualConfig(params = {}){
    const config = ref('{}');
    const currentFeed = ref(openCorrectConfig(params, config.value));

    watch(config, (newVal) => {
        currentFeed.value = openCorrectConfig(params, newVal);
    });

    return {currentFeed, config};
}