function scroll(event){
    const y = (event.target.getBoundingClientRect().top + window.scrollY) - 80;
    window.scroll({
        top: y,
        behavior: 'smooth'
    });
}
export const install = ({ app }) => {
    app.directive('scroll', {
        // called when the bound element's parent component
        // and all its children are mounted.
        mounted(el) {
            el.addEventListener('click', scroll);
        },
        // called when the parent component is unmounted
        unmounted(el) {
            el.removeEventListener('click', scroll);
        }
    })
}