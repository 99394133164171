<template>
  <div v-if="modal" class="fixed inset-0 bg-black bg-opacity-50 sm:p-8 overflow-auto z-[51]" @click.self="ask()">
    <div
        class="bg-white sm:rounded-lg mx-auto p-0 divide-solid divide-y divide-blue-200 min-h-fit"
        :class="modal?.type !== 'alert' ? 'md:container' : 'w-96'"
    >
      <HeaderBar v-if="modal?.type !== 'alert'">
        {{ modal?.label ?? 'Model' }}
        <div class="float-right flex flex-row gap-1">
          <svg
              v-if="currentOpenModels.length > 1"
              @click="currentOpenModels.pop()"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="size-5 float-right cursor-pointer"
          >
            <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M9 15 3 9m0 0 6-6M3 9h12a6 6 0 0 1 0 12h-3"
            />
          </svg>
          <svg
              @click="currentOpenModels = []"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="size-5 float-right cursor-pointer"
          >
            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12"/>
          </svg>
        </div>
      </HeaderBar>
      <BaseBox class="flex flex-col gap-4">
        <component :is="modal?.template" v-bind="modal?.data"/>
      </BaseBox>
    </div>
  </div>
</template>
<script setup>
import HeaderBar from "./components/share/HeaderBar.vue";
import BaseBox from "./components/BaseBox.vue";
import {useVModel} from "@vueuse/core";
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import {watch} from "vue";

const props = defineProps({
  modal: Object,
  openModals: Array,
  ask: Function,
});

const emit = defineEmits(['update:openModals']);

const currentOpenModels = useVModel(props,'openModals', emit);

watch(() => props.modal, () => {
  if(props.modal){
    disableBodyScroll(document.body);
  }
  else {
    enableBodyScroll(document.body);
  }
})
</script>