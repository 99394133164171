import {computed, ref, watch} from "vue";
import {useFetch} from "@vueuse/core";
import {detect} from "@/global/pop.js";
class website{
    constructor(config) {
        this.config = config;
    }
}

export function useData(currentConfig){
    const token = '2efe5a61-2d5e-42ff-9b14-cff7deee249e';
    const {data, error} = useFetch('https://nextgen.carads.io/virtualc/?token=' + token,{
        afterFetch: (data) => {
            console.log('data::', data)
            return data.data;
        }
    }).json();

    const url = ref('');
    const { data:extra, execute} = useFetch(url, { immediate: false, afterFetch: (data) => {
            console.log('virtualc::', data)
            return data.data;
    }}).json();

    watch(currentConfig, (newVal, oldValue) => {
        if(newVal.cid !== undefined && newVal.cid !== oldValue.cid){
            url.value = 'https://nextgen.carads.io/virtualc/' + newVal.cid + '?token=' + token;
            execute?.().then(r => {
                console.log('extra', extra.value)
            });
        }
    }, {deep: true});

    return {
        data,
        extra,
        error
    }
}

export function useWebsite(currentConfig, currentSettings, params){
    const w = window.opener || window;
    const hasElements = ref({
        app: false,
        slow_app: false,
        quick_search: false,
        slider: false,
        total: false,
        totals: false,
        forms: false,
        insurance: false,
        code: false
    }); // product_search
    const app = ref({
        type: params?.config ?? undefined,
        index: params?.index ?? params?.id ?? undefined,
        time: params?.time ?? undefined,
    });

    function findConfig(type){
        try{
            switch (type) {
                case 'app':
                case 'slow_app':
                    return JSON.parse(w.document.getElementById('carads_search_settings')?.textContent);
                case 'quick_search':
                    return JSON.parse(w.document.getElementById('quick_search').dataset.settings);
                case 'slider':
                    return [...w.document.querySelectorAll('.slider')].map((el) => {
                        let config = {};
                        const id = el.dataset.id;

                        try{
                            config = JSON.parse(w.document.getElementById('carads_slider_' + id).textContent)
                        }
                        catch (e){
                            console.error('Error parsing slider settings', e);
                        }

                        return {
                            id,
                            ...config
                        };
                    });
                case 'forms':
                    return [...w.document.querySelectorAll('.carads-f')].map((el) => {
                        let config = {};

                        try{
                            config = JSON.parse(el.dataset.settings)
                        }
                        catch (e){
                            console.error('Error parsing forms settings', e);
                        }

                        return config;
                    });
                case 'insurance':
                    return [...w.document.querySelectorAll('.carads-insurance')].map((el) => {
                        let config = {};

                        try{
                            config = JSON.parse(el.dataset.settings)
                        }
                        catch (e){
                            console.error('Error parsing insurance settings', e);
                        }

                        return config;
                    });
                case 'code':
                    return [...w.document.querySelectorAll('.carads-code')].map((el) => {
                        let config = {};

                        try{
                            config = JSON.parse(el.dataset.settings)
                        }
                        catch (e){
                            console.error('Error parsing code settings', e);
                        }

                        return config;
                    });
                case 'total':
                case 'totals':
                    return [...w.document.querySelectorAll('.carads-' + type)].map((el) => {
                        let config = {};

                        try{
                            config = JSON.parse(el.dataset.caradsSettings)
                        }
                        catch (e){
                            console.error('Error parsing total settings', e);
                        }

                        return config;
                    });
                default:
                    return {};
            }
        }
        catch (e){
            console.error('Error parsing settings', e);
        }

        return null;
    }

    const config = computed(() => {
        return {
            app:          hasElements.value.app ? findConfig('app') : null,
            slow_app:     hasElements.value.slow_app ? findConfig('slow_app') : null,
            quick_search: hasElements.value.quick_search ? findConfig('quick_search') : null,
            slider:       hasElements.value.slider    ? findConfig('slider') : null,
            total:        hasElements.value.total     ? findConfig('total') : null,
            totals:       hasElements.value.totals    ? findConfig('totals') : null,
            forms:        hasElements.value.forms     ? findConfig('forms') : null,
            insurance:    hasElements.value.insurance ? findConfig('insurance') : null,
            code:         hasElements.value.code      ? findConfig('code') : null,
        };
    });

    const activeConfig = ref(null);

    function sendEvent(payload, type = 'product_search'){
        console.log({
            detail: payload
        })
        w.dispatchEvent(new CustomEvent(type, {
            detail: payload
        }));
    }

    function getConfigs(){
        config.value = {
            app:          {},
            slow_app:     {},
            quick_search: {},
            slider:       [],
            total:        {},
            totals:       {},
            forms:        {},
            insurance:    {},
            code:         {},
        };
    }

    function update(){
        const data = detect(w);
        for(const key in data){
            hasElements.value[key] = Array.isArray(data[key]) ? (
                data[key].map((el) => {
                    if(key === 'slider'){
                        return el.dataset.id;
                    }
                    return el;
                })
            ) : data[key] !== null;
        }

        if(window?.opener){
            if(app.value.type === 'slider'){
                const con = (config.value[app.value.type] ?? []);

                const index = con.find((value, index) => {
                    return value.id === app.value.index;
                })

                currentConfig.value = JSON.stringify(index ?? {});
            }
            else {
                currentConfig.value = JSON.stringify(config.value[app.value.type] ?? {});
            }
        }
    }

    let timer = null;

    watch(() => currentSettings.value.output(), (value, oldValue) => {
        const currentType = currentSettings.value.getName();

        let full_reload = false;

        switch (currentType){
            case 'quick_search_update':
                full_reload = (
                    value.use_search === oldValue.use_search
                );
                break;
            case 'slider_update':
                break;
            case 'product_search':
            default:
                full_reload = (
                    value?.title !== oldValue?.title ||
                    value?.description !== oldValue?.description ||
                    value?.robots !== oldValue?.robots ||
                    value?.tags !== oldValue?.tags ||
                    value?.cid !== oldValue?.cid ||
                    value?.fid !== oldValue?.fid ||
                    value?.sort !== oldValue?.sort ||
                    value?.page_info !== oldValue?.page_info ||
                    value?.search_info !== oldValue?.search_info ||
                    value?.categories !== oldValue?.categories ||
                    value?.equipments !== oldValue?.equipments
                );
                break;
        }

        if(timer){
            clearTimeout(timer);
        }

        timer = setTimeout(() => {
            sendEvent({
                update: true,
                reload:full_reload,
                settings: currentSettings.value.output()
            }, currentType)
        }, 1000);

    }, {deep: true});

    return {
        config,
        hasElements,
        update,
        app
    }
}