<template>
  <div
      class="sticky top-0 bg-gray-50 z-50 text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700"
  >
    <ul class="container mx-auto min-w-96 flex flex-wrap -mb-px">
      <li class="me-2" v-for="(item, key) in menuElements">
        <button
            @click="show = item.key"
            class="inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
        >
          {{ item.label }}
        </button>
      </li>
    </ul>
  </div>
</template>
<script setup>
import {computed} from "vue";

const emit = defineEmits(['update'])
const props = defineProps({
  menu: Object,
  show: String
});

const show = computed({
  get: () => props.show,
  set: (value) => {
    emit('update', value)
  }
});

const menuElements = computed(() => {
  return Object.entries(props.menu).filter(([key,item]) => item).map(([key, item]) => {
    return {
      ...item,
      key
    }
  });
})
</script>