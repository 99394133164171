<template>
  <button :class="[color, size]"
  >
    <slot />
  </button>
</template>
<script setup>
import {computed, ref} from "vue";

const props = defineProps({
  color: {
    type: String,
    default: 'primary'
  },
  size: {
    type: String,
    default: 'default'
  }
});

const colorTable = ref({
  primary: 'text-white bg-blue-700 hover:bg-blue-800 focus:ring-blue-300 rounded-lg dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800',
  secondary: ' text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10  focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700',
  success: 'focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-green-300 rounded-lg dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800',
  danger: 'focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-red-300  rounded-lg dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900',
  info: 'focus:outline-none text-white bg-yellow-400 hover:bg-yellow-500 focus:ring-yellow-300 rounded-lg dark:focus:ring-yellow-900',
  light: 'text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-gray-100 rounded-lg dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700',
  dark: 'text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-gray-300 rounded-lg dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700',
  none: 'bg-transparent focus:outline-none',
});

const sizes = ref({
  default: 'text-sm px-5 py-2.5 font-medium focus:ring-4',
  small: 'text-xs px-3 py-1.5 font-light focus:ring-2',
  none: 'focus:outline-none',
});
const color = computed(() => {
  return colorTable.value?.[props.color] || colorTable.value.light;
});

const size = computed(() => {
  return sizes.value?.[props.size] || sizes.value.default;
});

</script>