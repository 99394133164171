import {createI18n} from "vue-i18n";
import da from "./../locales/da.json";
import en from "./../locales/en.json";

const numberFormats = {
    'en': {
        currency: {
            style: 'currency',
            currency: 'DKK',
            useGrouping: true,
            currencyDisplay: 'symbol',
            minimumFractionDigits: 0,
            maximumFractionDigits: 2
        },
        carShopCurrency: {style: 'currency', currency: 'DKK', minimumFractionDigits: 0, maximumFractionDigits: 2},
        decimal: {style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2},
        int: {style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0},
        km: {style: 'unit', unit: 'kilometer'},
        kg: {style: 'unit', unit: 'kilogram'},
        cm: {style: 'unit', unit: 'centimeter'},
        percent: {style: 'percent', useGrouping: false}
    },
    'da': {
        currency: {
            style: 'currency',
            currency: 'DKK',
            useGrouping: true,
            currencyDisplay: 'code',
            minimumFractionDigits: 0,
            maximumFractionDigits: 2
        },
        carShopCurrency: {style: 'currency', currency: 'DKK', minimumFractionDigits: 0, maximumFractionDigits: 2},
        decimal: {style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2},
        int: {style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0},
        km: {style: 'unit', unit: 'kilometer'},
        kg: {style: 'unit', unit: 'kilogram'},
        cm: {style: 'unit', unit: 'centimeter'},
        percent: {style: 'percent', useGrouping: false}
    }
};

const i18n = createI18n({
    locale: 'en',
    legacy: true,
    globalInjection: true,
    fallbackLocale: 'da',
    missingWarn: false,
    fallbackWarn: false,
    silentFallbackWarn: true,
    messages: {
        en,
        da
    },
    numberFormats: numberFormats
});

export const install = ({ app }) => {
    const consoleWarn = console.warn;
    console.warn = function (message) {
        if (message.includes('[intlify] Not found')) return;
        consoleWarn.apply(console, arguments);
    }

    app.use(i18n);
}